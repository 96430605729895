<template>
  <MainLayout>
    <div
      class="main-color pa-4 text-center white--text"
      style="font-size: 22px"
    >
      ตรวจสอบรางวัล
    </div>
    <div
      class="pa-4"
      @click="$router.push({ name: 'profile' })"
      style="cursor: pointer;"
    >
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span
        class="primary--text"
        >กลับ</span
      >
    </div>
    <div class="mt-4" v-if="rewards && rewards.length > 0">
      <div v-for="(r, index) in rewards" :key="index" class="pa-2 box-reward">
        <div class="d-flex justify-space-between mb-2">
          <div><b>แคมเปญ</b>&nbsp;&nbsp;&nbsp;{{ r.campaign_name }}</div>
          <div class="gray--text">
            {{ dayjs(r.timestamp).format("DD/MM/YYYY HH:mm") }}
          </div>
        </div>
        <div
          style="height: 170px"
          class="d-flex pl-5"
          v-for="p in r.productdetails"
          :key="p.id"
        >
          <div
            style="height: 170px; width: 160px"
            class="d-flex align-center justify-center"
          >
            <img :src="p.photo" width="140px" height="140px" />
          </div>
          <div class="pl-5 pt-10">
            <div class="pt-5 primary--text">
              <b>{{ p.name }}</b>
            </div>
            <div class="mb-3">
              <b>แลก&nbsp;{{ formattedNumber(p.points) }}&nbsp;แต้ม</b>
            </div>
          </div>
        </div>
        <div class="mt-5 gray--text pl-7">
          <div
            :class="{
              approve: ['จัดส่งแล้ว', 'รับสินค้าแล้ว'].includes(
                r.shipping_status
              ),
              waiting: !['จัดส่งแล้ว', 'รับสินค้าแล้ว'].includes(
                r.shipping_status
              ),
            }"
          >
            สถานะ&nbsp;:&nbsp;{{ r.shipping_status || "รอดำเนินการ" }}
          </div>
          <div>สาขา&nbsp;:&nbsp;{{ findBranch(r.branch_id) }}</div>
          <div v-if="r.shipping_status == 'รับสินค้าแล้ว'">
            วันที่รับ&nbsp;:&nbsp;{{
              r.shipping_at
                ? dayjs(r.shipping_at).format("DD/MM/YYYY HH:mm")
                : "-"
            }}
          </div>
          <div
            v-if="r.shipping_status == 'รับสินค้าแล้ว' && r.shipping_url"
            @click="seeImg(r.shipping_url)"
          >
            <img style="width:13px" src="@/assets/search.svg" />
            &nbsp;<a>ดูรูปเพิ่มเติม</a>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading
      spinner="bubbles"
      :identifier="infiniteId"
      @infinite="infiniteHandler"
    >
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infinite-loading>
    <div
      v-if="rewards.length == 0 && IsstateComplete"
      style="margin-top: 30%; text-align: center"
    >
      <h2 style="color: #979696">ยังไม่มีประวัติการแลกของรางวัล</h2>
    </div>
    <v-dialog v-model="showImageDialog">
      <div @click="showImageDialog = false">
        <img style="width:100%" :src="showImageDialogLink" />
      </div>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      branchData: [],
      rewards: [],
      state: null,
      infiniteId: new Date(),
      IsstateComplete: false,
      limit: 5,
      offset: 0,
      showImageDialog: false,
      showImageDialogLink: null,
    };
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  mounted() {},
  methods: {
    seeImg(link) {
      try {
        const linkTemp = link.split(";;");
        if (linkTemp[0]) {
          link = linkTemp[0];
        }
      } catch (error) {
        //
      } finally {
        this.showImageDialog = true;
        this.showImageDialogLink = link;
      }
    },
    findBranch(branchId) {
      try {
        const branch = this.branchData.find((branch) => branch.id == branchId);
        if (branch && branch.name) {
          return branch.name;
        } else {
          return "-";
        }
      } catch (error) {
        console.error(error);
      }
    },
    async formatGetBranch() {
      let rewards = this.rewards.map((reward) => reward.campaign_id);
      rewards = [...new Set(...rewards)];
      let branchPool = [];
      if (rewards && rewards.length) {
        for (let i = 0; i < rewards.length; i++) {
          branchPool.push(...(await this.getBranch()));
        }
        this.branchData = branchPool;
      }
    },
    async getBranch(campaign_id) {
      this.$store.commit("setLoading", true);
      let data = {
        page_id: this.pageId,
        campaign_id: campaign_id,
        limit: 99999,
        offset: 0,
      };
      try {
        let result = await api.getBranch(data);
        return result.data.data;
      } catch (error) {
        console.error(error);
        return [];
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    async getData() {
      let data = {
        page_id: this.pageId,
        user_id: this.userId,
      };
      try {
        let result = await api.GetRewardHistory(data);
        if (result && result.data && result.data.data) {
          this.rewards = result.data.data;
          this.formatGetBranch();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async infiniteHandler(state) {
      this.IsstateComplete = false;
      this.state = state;
      var length = await this.getData();
      this.offset += 5;
      if (length) {
        this.state.loaded();
      } else {
        this.state.complete();
        this.IsstateComplete = true;
      }
    },
  },
};
</script>

<style scoped>
.box-reward {
  border: 1px solid #e6e6e6;
  box-shadow: 5px 5px #e6e6e6;
  margin-bottom: 20px;
}
.approve {
  color: #0ab826;
}
.notapprove {
  color: #ff1414;
}
.waiting {
  color: #ffcc4a;
}
</style>
