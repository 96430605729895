<template>
  <MainLayout>
    <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
      ตรวจสอบรางวัล
    </div>
    <div class="pa-4" @click="onBack"  style="cursor: pointer;">
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;
      <span
        class="primary--text"
        >
        กลับ
        </span
      >
    </div>
    <div class="topic">
      <div>เงื่อนไขการใช้งาน</div>
      <div>Lotus's E-coupons</div>
    </div>
    <div class="disp">
      <p>
        <ul>
          <li>E-Coupons ไม่สามารถถอน เปลี่ยนเป็นเงินสด หรือรูปแบบการชาระอื่น และไม่สามารถทอนส่วนต่างเป็นเงินสด กรณีซื้อสินค้าน้อยกว่ามูลค่าของE-Coupons</li>
          <li>สามารถใช้ซื้อสินค้าที่โลตัส ทุกสาขา และต้องใช้ก่อนวันหมดอายุ</li>
          <li>สามารถใช้ได้เพียงครั้งเดียว</li>
          <li>ไม่สามารถใช้ซื้อสินค้าประเภทเครื่องดื่มแอลกอฮอล์ บุหรี่ บัตรเติมเงิน บัตรของขวัญ นมดัดแปลงสาหรับทารกและนมดัดแปลงสูตรต่อเนื่องสาหรับทารกและเด็ก (สูตร 1 และ2) Bill Payment และค่าธรรมเนียม บริการไปรษณีย์ ศูนย์อาหาร โลตัสช้อปออนไลน์ และสินค้า/ร้านค้าในบริเวณพื้นที่เช่าของโลตัส</li>
          <li>การใช้ E-Coupons จะต้องกดรับสิทธิ์ที่หน้าเคาเตอร์แคชเชียร์เท่านั้น ขอสงวนสิทธิ์ไม่ให้ใช้รูปภาพที่มีการบันทึกเก็บไว้ หรือพิมพ์บนกระดาษ</li>
          <li>ส่วนลดจากการใช้ จะไม่ถูกนามาคานวณเป็นแต้มคลับการ์ด</li>
          <li>บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่จาเป็นต้องแจ้งให้ทราบล่วงหน้า</li>
        </ul>
      </p>
    </div>
  </MainLayout>
</template>

<script>
export default {
  data() {
    return {
      barcode: null,
      amount: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0)
    this.barcode = this.$route.query.barcode;
    this.amount = this.$route.query.amount;
    if (!this.barcode && !this.amount) {
      this.$router.push({name: 'none'})
    }
  },
  methods: {
    onBack() {
      this.$router.push({
        name: "ecoupon",
        query: { barcode: this.barcode, amount: this.amount },
      });
    },
  },
};
</script>

<style scoped>
.topic {
  text-align: center;
  background-color: #b7b7b7;
  color: white;
  padding: 10px;
}
.disp {
  background-color: #f3f3f3;
  color: black;
  padding: 25px;
}
</style>
