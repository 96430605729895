<template>
  <MainLayout>
    <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
      {{ topicHeader }}
    </div>
    <div class="pa-4" @click="$router.push({ name: 'profile' })" style="cursor: pointer;"><v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span></div>
    <div class="text-center primary--text mb-4" v-if="selectedCampaign">
      <v-menu offset-y attach rounded :disabled="filterCampaigns && filterCampaigns.length == 0">
        <template v-slot:activator="{ on, attrs }">
          <div class="topic mt-2 mb-5" v-bind="attrs" v-on="on">{{ selectedCampaign.name }}&nbsp;<v-icon v-if="filterCampaigns && filterCampaigns.length > 0" color="primary">mdi-chevron-down</v-icon></div>
        </template>
        <v-list>
          <v-list-item v-for="fc in filterCampaigns" :key="fc.id">
            <v-list-item-title @click="onChooseCampaign(fc)">{{ fc.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="mt-4" v-if="lists && lists.length > 0">
      <div v-for="(d, index) in lists" :key="index" class="pa-5 box-reward">
        <div>
          <p class="text-right" style="color: gray">
            <span style="font-size: 13px">{{ dayjs(d.updated_timestamp).format("DD/MM/YYYY HH:mm") }}</span>
          </p>
          <!-- <div class="d-flex">
            <div class="list-topic"><b>ชื่อแคมเปญ</b></div>
            <div>{{ d.campaign_name }}</div>
          </div> -->
          <div class="d-flex" v-if="rewardType == 'receipt'">
            <div class="list-topic"><b>เลขที่ใบเสร็จ</b></div>
            <div>{{ d.ref_receipt }}</div>
          </div>
          <div class="d-flex" v-if="rewardType == 'receipt'">
            <div class="list-topic"><b>สถานะ</b></div>
            <div
              :class="{
                approve: d.status == 'approved',
                notapprove: d.status == 'reject',
                waiting: ['pending', 'waiting_for_delivery'].includes(d.status),
              }"
            >
              {{ d.status | statusWording }}
            </div>
          </div>
          <div class="d-flex" v-if="d.status == 'reject'">
            <div class="list-topic"><b>สาเหตุ</b></div>
            <div>{{ d.reject_reason }}</div>
          </div>
        </div>
        <div class="mt-1" v-if="d.status == 'approved'">
          <div
            v-if="selectedCampaign.type == 'full' || (selectedCampaign.type == 'collect' && rewardType == 'ecoupon') || selectedCampaign.type == 'lucky_draw'"
            class="primary--text"
            style="
              padding: 15px;
              background: rgb(210, 229, 255);
              border: 1px solid #00626a;
              border-radius: 8px;
            "
          >
            <b>คูปองที่ได้รับ ({{ d.coupon.length }})</b>
            <div style="font-size: 12px; color: gray; margin-bottom: 8px">
              คลิกรูปกล่องของรางวัลด้านล่างเพื่อตรวจสอบ
            </div>
            <div style="display: flex; justify-content: center">
              <input type="checkbox" :id="'expend' + index" />
              <v-layout class="mt-2 mx-auto gift-cover " justify-center style="flex-wrap: wrap; max-width: 270px;">
                <template v-for="(c, cindex) in d.coupon">
                  <v-flex :key="cindex" shrink class="px-1">
                    <v-btn
                      icon
                      class="gift-box mb-4"
                      @click="checkEcoupon(c)"
                      :class="{
                        'gift-box': !c.is_used,
                        'gift-box-offline': c.is_used,
                      }"
                      style="width: 40px; height: 40px"
                    >
                      <v-icon
                        :class="{
                          'primary--text': !c.is_used,
                          'offline-color': c.is_used,
                        }"
                        >mdi-ticket-confirmation</v-icon
                      >
                    </v-btn>
                  </v-flex>
                </template>
              </v-layout>
            </div>
            <div class="text-center" v-if="d.coupon.length > 5">
              <label :for="'expend' + index"><v-icon color="primary">mdi-menu-up</v-icon>ดูเพิ่ม</label>
            </div>
          </div>
          <div v-else-if="selectedCampaign.type == 'collect'">
            <div class="d-flex primary--text">
              <div class="list-topic"><b>แต้มที่ได้รับ</b></div>
              <div>
                <b>{{ formattedNumber(d.score || 0) }}&nbsp;แต้ม</b>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="(selectedCampaign.type == 'full' && d.status == 'reject') || (selectedCampaign.type == 'collect' && rewardType == 'receipt')">
          <v-btn color="primary" rounded outlined block @click="openReceipt(d)"><v-icon color="primary">mdi-square-edit-outline</v-icon>&nbsp;ดูใบเสร็จ</v-btn>
        </div>
        <!-- <div class="mt-3" v-if="!['approved', 'reject'].includes(d.status)">
          <v-btn color="primary" block disabled rounded
            ><v-icon color="primary">mdi-gift-open</v-icon
            >&nbsp;ตรวจสอบรางวัล</v-btn
          >
        </div> -->
      </div>
    </div>
    <infinite-loading spinner="bubbles" :identifier="infiniteId" @infinite="infiniteHandler">
      <span slot="no-more"></span>
      <span slot="no-results"></span
    ></infinite-loading>
    <div v-if="lists.length == 0 && IsstateComplete" style="margin-top: 30%;padding-bottom: 30px; text-align: center">
      <h2 style="color: #979696">{{ noResultMsg }}</h2>
      <v-divider class="mt-8"></v-divider>
    </div>

    <ReceviceEcoupon v-if="selectedCampaign" :campaign="selectedCampaign" />

    <!-- Show receipt -->
    <v-dialog v-model="showDetailDialog" fullscreen transition="dialog-bottom-transition">
      <ReceiptDetail :img="imgPreview" @onClose="showDetailDialog = false" />
    </v-dialog>
  </MainLayout>
</template>

<script>
import dayjs from "dayjs";
import api from "@/api";
import ReceiptDetail from "@/components/ReceiptDetail.vue";
import InfiniteLoading from "vue-infinite-loading";
import ReceviceEcoupon from "../PoolEcoupon/ReceviceEcoupon";

export default {
  components: {
    ReceiptDetail,
    InfiniteLoading,
    ReceviceEcoupon,
  },
  computed: {
    dayjs() {
      return dayjs;
    },
    filterCampaigns() {
      let result = [];
      if (this.selectedCampaign) {
        result = this.campaigns.filter((f) => f.id != this.selectedCampaign.id);
      }
      return result;
    },
  },
  data() {
    return {
      lists: [],
      colorStatus: "approved",
      imgPreview: null,
      showImageDialog: false,
      showDetailDialog: false,
      limit: 5,
      offset: 0,
      state: null,
      infiniteId: +new Date(),
      IsstateComplete: false,
      campaignType: "full",
      rewardType: "receipt",
      selectedCampaign: null,
      campaigns: [],
      topicHeader: "E-COUPON",
      noResultMsg: "ยังไม่มีประวัติการแลกของรางวัล",
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    let { receipt_id, action } = this.$route.query;
    if (receipt_id && action && action == "reject") {
      await this.getReceiptById(receipt_id);
    }

    // Optional but default is full campaign
    let { campaignType, type } = this.$route.query;
    if (campaignType) {
      this.campaignType = campaignType;
    }
    if (type) {
      this.rewardType = type;
      if (type == "receipt") {
        this.topicHeader = "ประวัติสะสมแต้ม";
        this.noResultMsg = "ยังไม่มีประวัติการสะสมแต้ม";
      }
    }

    this.$store.commit("setLoading", true);
    await this.getCampaigns();
    if (this.campaigns && this.campaigns.length > 0) {
      this.infiniteId += 1;
    }
    this.$store.commit("setLoading", false);
  },
  methods: {
    async getReceipts() {
      let params = {
        page_id: this.pageId,
        limit: this.limit,
        offset: this.offset,
        campaign_id: this.selectedCampaign.id,
        campaign_type: this.selectedCampaign.type,
        is_ecoupon: this.rewardType == "ecoupon",
      };
      try {
        let result = await api.getReceiptsByCampaignId(this.userId, params);
        if (result && result.data && result.data.data) {
          this.lists.push(...result.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getReceiptById(receiptId) {
      this.$store.commit("setLoading", true);
      let data = {
        receiptId: receiptId,
        userId: this.userId,
      };
      try {
        let result = await api.getReceiptByReceiptId(data);
        if (result.data.data) {
          this.openReceipt(result.data.data);
        } else {
          this.$router.push({ name: "none" });
        }
      } catch (error) {
        this.$router.push({ name: "none" });
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    checkEcoupon(data) {
      console.log(data);
      this.$router.push({
        name: "ecoupon",
        query: { barcode: data.code, amount: data.amount },
      });
    },
    openReceipt(data) {
      if (typeof data.attachment == "string") {
        this.imgPreview = JSON.parse(data.attachment);
      } else {
        this.imgPreview = data.attachment;
      }
      this.showDetailDialog = true;
    },
    async infiniteHandler(state) {
      this.IsstateComplete = false;
      this.state = state;
      if (!this.selectedCampaign) {
        this.state.complete();
        return;
      }
      var length = await this.getReceipts();
      this.offset += 5;
      if (length) {
        this.state.loaded();
      } else {
        this.state.complete();
        this.IsstateComplete = true;
      }
    },
    async getCampaigns() {
      try {
        let result = await api.getParticipationCampaign({
          page_id: this.pageId,
          user_id: this.userId,
        });
        if (result && result.data && result.data.data && result.data.data.length) {
          let filterCampaign = result.data.data;
          this.campaigns = filterCampaign.sort((a, b) => {
            return +new Date(b.start_timestamp) - +new Date(a.start_timestamp);
          });
          this.selectedCampaign = this.campaigns.length > 0 ? this.campaigns[0] : null;
        } else {
          await this.getActiveCampaigns();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getActiveCampaigns() {
      this.$store.commit("setLoading", true);
      this.loading = true;

      try {
        let result = await api.getCampaigns({ page_id: this.pageId });
        if (result && result.data && result.data.data) {
          this.campaigns = result.data.data;
          this.selectedCampaign = this.campaigns.length > 0 ? this.campaigns[0] : null;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    async onChooseCampaign(campaign) {
      this.lists = [];
      this.selectedCampaign = campaign;
      this.offset = 0;
      this.infiniteId += 1;
    },
  },
  filters: {
    statusWording(val) {
      let master = [
        { value: "approved", text: "อนุมัติ" },
        { value: "reject", text: "ไม่อนุมัติ" },
        { value: "pending", text: "รอการอนุมัติ" },
        { value: "waiting_for_delivery", text: "รอการอนุมัติ" },
      ];
      return master.find((f) => f.value == val).text;
    },
  },
};
</script>

<style scoped>
.approve {
  color: #0ab826;
}
.notapprove {
  color: #ff1414;
}
.waiting {
  color: #ffcc4a;
}
.list-topic {
  flex-basis: 110px;
}
.box-reward {
  border: 1px solid #e6e6e6;
  box-shadow: 5px 5px #e6e6e6;
  margin-bottom: 20px;
}
.gift-box {
  /* width: max-content; */
  border: 2px solid#00626a;
  /* border-radius: 50%; */
  /* padding: 10px; */
  /* margin: 0px 10px 10px 0px; */
}
.gift-box-offline {
  /* width: max-content; */
  border: 2px solid #979696;
  background-color: #eeebeb;
  /* border-radius: 50%; */
  /* padding: 10px; */
  /* margin: 0px 10px 10px 0px; */
}
.offline-color {
  color: #979696;
}
[id^="expend"] {
  display: none;
}
[id^="expend"] + .gift-cover {
  max-height: 55px;
  overflow: hidden;
  transition: all 0.3s ease;
}
[id^="expend"]:checked + .gift-cover {
  max-height: max-content;
}
</style>
