import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#00626a",
        secondary: "#3B81E1",
        thirdcolor: "#FDDDC6",
        accent: "#8c9eff",
        error: "#b71c1c",
      },
    },
  },
};

export default new Vuetify(opts);
