<template>
  <v-app>
    <v-main>
      <router-view />
      <v-overlay :value="IsOverlay" color="#ffffff" opacity="0.8" z-index="200001">
        <div>
          <img src="@/assets/logo.png" width="95px" />
        </div>
        <div>
          <v-progress-linear indeterminate color="primary" height="10" striped></v-progress-linear>
        </div>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    IsOverlay() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style>
.Kanit {
  font-family: "K2D", sans-serif !important;
}
/* rgba(232, 58, 8, 1) 0%,
    rgba(232, 58, 8, 1) 50%,
    rgba(232, 131, 59, 1) 100% */

.main-color {
  background: rgb(0, 98, 106) !important;
  /* background: linear-gradient(180deg, rgba(0, 98, 106, 1) 0%, rgba(4, 132, 143, 1) 50%, rgba(6, 202, 219, 1) 100%) !important; */
}

/* rgba(232, 58, 8, 1) 0%,
    rgba(238, 96, 22, 1) 50%,
    rgba(232, 131, 59, 0.47) 100% */

.secondary-color {
  background: rgb(0, 98, 106) !important;
  /* background: linear-gradient(180deg, rgba(0, 98, 106, 1) 0%, rgba(4, 132, 143, 1) 50%, rgba(6, 202, 219, 0.47) 100%) !important; */
}

/* rgba(242, 148, 117, 1) 0%,
    rgba(245, 162, 123, 1) 50%,
    rgba(242, 178, 138, 1) 100% */

.thrid-color {
  background: #00626a !important;
  /* background: linear-gradient(180deg, rgb(129, 174, 236) 0%, rgb(160, 185, 219) 50%, rgb(210, 229, 255) 100%) !important; */
  color: white !important;
}

.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height-with-nav {
  height: calc(100vh - 65px);
}

.text-primary {
  color: #036169;
}
</style>
