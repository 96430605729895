<template>
  <MainLayout showBottomNav>
    <v-carousel cycle height="270" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item v-for="(item, i) in banners" :key="i" reverse-transition="fade-transition" transition="fade-transition">
        <div
          :style="{
            'background-image': 'url(' + item + ')',
            'background-size': '100% 100%',
            'background-repeat': 'no-repeat',
          }"
          style="height: 270px;"
        ></div>
      </v-carousel-item>
    </v-carousel>
    <div class="pa-5">
      <h3 class="mb-5 primary--text">
        กรุณาแนบใบเสร็จการสั่งซื้อสินค้า
      </h3>
      <div class="mb-3">
        <ValidationObserver ref="observer">
          <ValidationProvider rules="required" name="ใบเสร็จ" v-slot="{ errors }">
            <UploadDocument :errors="errors" :key="keyupload" :showpreview="true" v-model="image" :max="imageAmount" :disabled="IsRestricted"> </UploadDocument>
          </ValidationProvider>
        </ValidationObserver>
      </div>
      <div v-if="description" class="mb-10 mt-10 primary--text" style="font-size: 14px; white-space: pre-wrap;">
        {{ description }}
        <!-- *ใบเสร็จที่สามารถเข้าร่วมรายการได้ ใบเสร็จรับเงิน / ใบกำกับภาษี ,
        ใบเสร็จรับเงิน , บิล POS , กรณี บิลเงินสด
        ต้องถ่ายภาพสินค้าคู่กับบิลที่เห็นรายละเอียดชัดเจนเป็นหลักฐาน
        แนบมาด้วย<br />
        *เริ่มวันที่ 18 ต.ค. 2564 - 30 พ.ย. 2564 เท่านั้น<br />
        *ถ่ายรูปบิลใบเสร็จให้ชัดเจน เห็นรายละเอียดครบถ้วน<br />
        *อัปโหลดบิลใบเสร็จ 1 เลขที่ใบเสร็จ ต่อ 1 ครั้งเท่านั้น<br />
        *อัปโหลดรูปภาพได้สูงสุด 5 รูปภาพ -->
      </div>
      <v-btn color="main-color" class="white--text" block @click="onSubmit" rounded large :disabled="IsRestricted">อัปโหลดใบเสร็จ</v-btn>
      <div class="primary--text mt-2" style="font-size: 14px;">
        *อัปโหลดรูปภาพได้สูงสุด 5 รูปภาพ
      </div>
    </div>
    <v-dialog v-model="showDialog" width="450px" height="450px" persistent>
      <v-card>
        <div style="display: flex; justify-content: center; text-align:center">
          <div style="width: 350px; margin: 30px">
            <v-icon color="primary" large class="mb-4" style="font-size: 95px;">mdi-check-circle</v-icon>
            <div class="mb-4">
              <h3><span class="primary--text">อัปโหลดใบเสร็จสำเร็จ</span><br />กรุณารอการตรวจสอบ<br />และแจ้งกลับจากระบบ</h3>
            </div>
            <v-btn color="main-color" class="white--text" block @click="onClose" rounded large>ตกลง</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Show Restricted dialog -->
    <v-dialog v-model="showRestrictedDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align:center">
          <div style="width: 350px; margin: 30px">
            <v-icon color="primary" large class="mb-4" style="font-size: 95px;">mdi-alert-circle</v-icon>
            <div class="mb-4">
              <h3><span class="primary--text">ไม่สามารถดำเนินการได้</span><br />เนื่องจากจำกัดสิทธิ์การอัปโหลดได้เพียง 1 ครั้งต่อวัน</h3>
            </div>
            <v-btn color="main-color" class="white--text" block @click="showRestrictedDialog = false" rounded large>ตกลง</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
import UploadDocument from "@/components/UploadFile.vue";
import dayjs from "dayjs";
export default {
  components: {
    UploadDocument,
  },
  data() {
    return {
      image: [],
      keyupload: 1,
      showDialog: false,
      showRestrictedDialog: false,
      IsRestricted: false,
      imageAmount: 5,
      banners: [require("@/assets/bannernew.jpg")],
      campaign: null,
      description: "",
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    let { campaign } = this.$route.params;
    if (campaign) {
      this.manageCampaign(campaign);
    } else {
      this.$router.push({ name: "campaign" });
    }
  },
  methods: {
    async CheckUploadPermission() {
      this.$store.commit("setLoading", true);
      let data = {
        userId: this.userId,
        date: dayjs().format("YYYY-MM-DD"),
      };
      let result = await api.CheckRestrictedUpload(data);
      this.$store.commit("setLoading", false);
      if (result.responsecode == 200) {
        if (result.status) {
          // restricted
          this.IsRestricted = true;
          this.showRestrictedDialog = true;
        } else {
          this.IsRestricted = false;
        }
      }
    },
    async onSubmit() {
      let IsValid = await this.$refs.observer.validate();
      if (!IsValid) {
        return;
      }

      let payload = {
        user_id: this.userId,
        attachment: this.image,
        page_id: this.pageId,
        campaign_id: this.campaign.id,
      };
      this.$store.commit("setLoading", true);
      try {
        await api.uploadReceipt(payload);
        this.image = [];
        this.$store.dispatch("sendMessage", "อัปโหลดใบเสร็จสำเร็จ");
        this.showDialog = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    onClose() {
      this.showDialog = false;
      this.showRestrictedDialog = false;
      this.$liff.closeWindow();
    },
    manageCampaign(campaign) {
      this.campaign = campaign;
      this.banners = campaign.banner;
      this.description = campaign.receipt_condition;
    },
  },
};
</script>
