import axios from "axios";
const serverUrl = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTHORIZATION;
axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_API_KEY;

const instance = () =>
  axios.create({
    baseURL: serverUrl,
    timeout: 30000,
  });

export const member = {
  register(body) {
    return instance().post(`${serverUrl}/member`, body);
  },
};
