<template>
  <div class="text-center">
    <div
      class="d-flex primary--text"
      style="width: 150px; border: 1px solid #00626a;"
    >
      <div
        :class="{ disabled: amount == 0 }"
        style="width: 50px; font-size: 28px; border-right: 1px solid #00626a;"
        @click="decrease"
      >
        -
      </div>
      <div style="width: 50px; font-size: 30px;">
        {{ formattedNumber(amount) }}
      </div>
      <div
        :class="{
          disabled:
            remainingPoints < reward.points ||
            calculatePreRemainingPoints > remainingPoints,
        }"
        style="width: 50px; font-size: 28px; border-left: 1px solid #00626a;"
        @click="increase"
      >
        +
      </div>
    </div>
    <v-dialog v-model="showConfirmDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align:center">
          <div style="width: 350px; margin: 30px">
            <div class="mb-4">
              <h3>
                ต้องการลบรายการนี้หรือไม่
              </h3>
            </div>
            <v-btn
              outlined
              color="gray"
              class="mr-2 black--text"
              @click="showConfirmDialog = false"
              rounded
              large
              >ยกเลิก</v-btn
            >
            <v-btn
              color="main-color"
              class="white--text"
              @click="confirmDelete"
              rounded
              large
            >
              ลบ</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    reward: {
      type: Object,
      default: null,
    },
    IsAddToCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amount: this.value,
      showConfirmDialog: false,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.amount = this.value;
      }
    },
  },
  computed: {
    remainingPoints() {
      return this.$store.state.remainingPoints;
    },
    calculatePreRemainingPoints() {
      return (this.reward.points / this.reward.quantity) * (this.amount + 1);
    },
  },
  methods: {
    decrease() {
      if (this.IsAddToCart && this.amount == 1) {
        this.showConfirmDialog = true;
      } else {
        this.amount--;
        this.$emit("input", this.amount);
        if (this.IsAddToCart) {
          this.AddToCard("-");
        }
      }
    },
    increase() {
      this.amount++;
      this.$emit("input", this.amount);
      if (this.IsAddToCart) {
        this.AddToCard("+");
      }
    },
    AddToCard(mode = "+") {
      this.reward.quantity = this.amount;
      console.log("test", mode);
      this.$store.dispatch("addToCard_v3", { mode, data: this.reward });
    },
    confirmDelete() {
      this.showConfirmDialog = false;
      this.amount = 0;
      this.AddToCard("-");
    },
  },
};
</script>

<style scoped>
.disabled {
  background: #d3d2d2;
  color: black;
  pointer-events: none;
}
</style>
