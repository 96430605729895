<template>
  <MainLayout :showBottomNav="!loading">
    <div v-if="!loading">
      <div class="text-center ma-9" style="font-size: 22px">
        <b>กรุณาเลือกแคมเปญ</b>
      </div>
      <v-layout v-if="!campaigns || campaigns.length == 0" fill-height justify-center align-center row>
        <v-flex class="text-center">
          ไม่มีแคมเปญเปิดอยู่ ณ ขณะนี้
        </v-flex>
      </v-layout>
      <div class="d-flex justify-center mb-5" v-for="c in campaigns" :key="c.id" @click="chooseCampaign(c)">
        <div class="text-right pr-6 pl-3" style="width: 180px">
          <img :src="c.image" alt="campaignImage" style="border-radius: 50%; width: 150px; height: 150px; z-index: 2" />
        </div>
        <div style="width: 280px" class="pr-3">
          <b style="font-size: 22px" class="primary--text">{{ c.name }}</b>
          <p class="gray--text" style="font-size: 14px">{{ c.description }}</p>
          <p class="black--text mb-2" style="font-size: 14px">หมดเขต&nbsp;{{ dayjs(c.end_timestamp).format("DD/MM/YYYY") }}</p>
          <v-btn outlined color="primary" rounded small @click.stop="readConditionDialog(c)">อ่านเงื่อนไข<v-icon color="primary">menu-right</v-icon></v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="IsConditionDialog" persistent width="500px" height="500px" scrollable>
      <v-card v-if="selectedCampaign">
        <div class="primary--text text-right pa-3" @click="closeConditionDialog" style="cursor: pointer">
          x
        </div>
        <div class="text-center primary--text mb-2">เงื่อนไขแคมเปญ</div>
        <div class="pa-4">
          {{ selectedCampaign.condition }}
        </div>
      </v-card>
    </v-dialog>

    <!-- Alert for limit participation -->
    <v-dialog v-model="limitParticipationDialog" persistent width="500px" scrollable>
      <v-card v-if="limitParticipationDialog">
        <div class="primary--text text-right pa-3" @click="limitParticipationDialog = false" style="cursor: pointer">
          x
        </div>
        <div class="text-center primary--text mb-2">ไม่สามารถแนบใบเสร็จได้</div>
        <div class="pa-4 text-center">
          คุณเข้าร่วมแคมเปญนี้ ครบตามจำนวนครั้งที่กำหนดแล้ว
        </div>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
export default {
  data() {
    return {
      campaigns: [],
      loading: true,
      selectedCampaign: null,
      IsConditionDialog: false,
      limitParticipationDialog: false,
    };
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCampaigns();
  },
  methods: {
    async getCampaigns() {
      this.$store.commit("setLoading", true);
      this.loading = true;

      try {
        let result = await api.getCampaigns({ page_id: this.pageId });
        if (result && result.data && result.data.data) {
          this.campaigns = result.data.data;
          if (this.campaigns && this.campaigns.length == 1) {
            this.chooseCampaign(this.campaigns[0]);
          } else this.loading = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    async chooseCampaign(campaign) {
      this.loading = true;
      this.$store.commit("setLoading", true);
      let data = {
        page_id: this.pageId,
        user_id: this.userId,
        campaign_id: campaign.id,
        limitParticipation: campaign.limit_participation,
      };
      try {
        let result = await api.CheckCampaignParticipationByCampaignIdAndUserId(data);
        if (result && result.data) {
          if (!result.data.status) {
            this.$router.push({
              name: "uploadReceipt",
              params: { campaign: campaign },
            });
          } else {
            this.limitParticipationDialog = true;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.$store.commit("setLoading", false);
      }
    },
    readConditionDialog(campaign) {
      this.selectedCampaign = campaign;
      this.IsConditionDialog = true;
    },
    closeConditionDialog() {
      this.IsConditionDialog = false;
      this.selectedCampaign = null;
    },
  },
};
</script>
