<template>
  <v-text-field
    :oninput="
      maxlength !== undefined
        ? `javascript: if (this.value.length > ${maxlength}) this.value = this.value.slice(0, ${maxlength});`
        : ''
    "
    :class="center ? 'centered-input' : ''"
    :value="datavalue"
    :outlined="outlined"
    :placeholder="placeholder"
    :solo="solo"
    :filled="filled"
    :loading="loading"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :prepend-inner-icon="prependInnerIcon"
    :append-outer-icon="appendOuterIcon"
    :autofocus="autofocus"
    :background-color="backgroundColor"
    :clearIcon="clearIcon"
    :clearable="clearable"
    :single-line="singleLine"
    :solo-inverted="soloInverted"
    :readonly="readonly"
    :reverse="reverse"
    :type="type"
    :rounded="rounded"
    :hide-details="hideDetails ? true : false"
    :dense="dense"
    :dark="dark"
    :error="error"
    :error-messages="errorMessages"
    :disabled="disabled"
    @click:append="$emit('click:append')"
    @keypress="onKeyPress"
    @paste="onKeyPress"
    @input="onInput($event)"
  ></v-text-field>
</template>

<script>
export default {
  props: [
    "value",
    "maxlength",
    "numberOnly",
    "decimalNumber",
    "dark",
    "disabled",
    "errorMessages",
    "error",
    "dense",
    "hideDetails",
    "prependIcon",
    "prependInnerIcon",
    "rounded",
    "type",
    "reverse",
    "readonly",
    "soloInverted",
    "singleLine",
    "clearIcon",
    "clearable",
    "backgroundColor",
    "outlined",
    "placeholder",
    "solo",
    "filled",
    "appendIcon",
    "appendOuterIcon",
    "autofocus",
    "loading",
    "center",
  ],
  computed: {
    datavalue() {
      var result = this.value;
      if (this.currency !== undefined || this.currency) {
          return;
      }
      return result;
    },
  },
  methods: {
    removeHiddenSpace(e) {
      try {
        var result = e.trim();
        // eslint-disable-next-line no-irregular-whitespace
        result = result.replace(/(​|“|”|‘|’)/gim, "");
        if (result) {
          e = result;
          return result;
        } else return "";
      } catch (error) {
        return e;
      }
    },
    onKeyPress(event) {
      var e = this.removeHiddenSpace(event);
      if (this.numberOnly !== undefined || this.numberOnly) {
        this.onlyNumber(e);
      } else if (this.decimalNumber !== undefined || this.decimalNumber) {
        this.onlyDecimalNumber(e);
      }
    },

    onlyNumber(event) {
      var result = event.charCode >= 48 && event.charCode <= 57;
      if (result) {
        // return result;
      } else {
        event.preventDefault();
      }
    },
    onlyDecimalNumber(event) {
      var result =
        (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46;
      if (result) {
        // return result;
      } else {
        event.preventDefault();
      }
    },
    onInput(e) {
      this.$emit("input", this.removeHiddenSpace(e));
    },
  },
};
</script>

<style >
.centered-input input {
  text-align: center;
}
</style>