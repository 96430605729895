import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
const serverUrl = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTHORIZATION;
axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_API_KEY;
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  // eslint-disable-next-line no-unused-vars
  (error) => {
    failAlert("เกิดข้อผิดพลาด");
    // return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // trigger 'loading=false' event here
    return response;
  },
  // error
  // eslint-disable-next-line no-unused-vars
  (error) => {
    if (!error.response) {
      failAlert("เกิดข้อผิดพลาด");
    }
  }
);

const failAlert = (reason) => {
  Swal.fire("พบบางอย่างไม่ถูกต้อง", reason, "error");
};

export default {
  async getProfile(params) {
    let result = await axios.get(serverUrl + "/member", { params });
    if (result && result.data && result.data.data) {
      store.commit("setUserData", result.data.data);
      return true;
    } else {
      store.commit("setUserData", null);
      return false;
    }
  },
  uploadReceipt(data) {
    return axios.post(serverUrl + "/receipt", data);
  },
  getStore(params) {
    return axios.get(`${serverUrl}/campaign/store`, { params });
  },
  getBranch(params) {
    return axios.get(`${serverUrl}/campaign/branch`, { params });
  },
  async getReceipts(userId, params) {
    let result = await axios.get(serverUrl + "/receipt/user/" + userId, { params: params });
    return result.data;
  },
  async checkValidEcoupon(data) {
    let result = await axios.get(serverUrl + "/ecoupon/validation?userId=" + data.userId + "&barcode=" + data.barcode);
    return result.data;
  },
  getReceiptByReceiptId(data) {
    return axios.get(serverUrl + "/receipt/" + data.receiptId, {
      params: { userId: data.userId },
    });
  },
  async CheckRestrictedUpload(data) {
    let result = await axios.get(serverUrl + "/upload/member/" + data.userId, {
      params: { date: data.date },
    });
    return result.data;
  },
  getCampaigns(data) {
    return axios.get(serverUrl + "/campaign/enabled", { params: data });
  },
  async getRegisterBackground(data) {
    let result = await axios.get(serverUrl + "/background/register", { params: data });
    return result.data;
  },
  getReceiptsByCampaignId(userId, params) {
    return axios.get(serverUrl + "/receipt/user/" + userId, { params });
  },
  getCampaignRewards(data) {
    return axios.get(serverUrl + "/campaign/reward", { params: data });
  },
  getRewardCategoriesByCampaignId(params) {
    return axios.get(serverUrl + "/campaign/reward/category", {
      params,
    });
  },
  getRewardByCampaignIdAmdCategory(params) {
    return axios.get(serverUrl + "/campaign/reward/product", { params });
  },
  async getPointsByCampaignId(data) {
    return await axios.get(serverUrl + "/campaign/reward/user/points", {
      params: data,
    });
  },
  getMyCart(data) {
    return axios.get(serverUrl + "/campaign/reward/user/cart", { params: data });
  },
  async AddRewardToCart(data) {
    try {
      let result = await axios.post(serverUrl + "/campaign/reward/user/cart", data);
      return result.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async EditRewardToCart(data) {
    let result = await axios.put(serverUrl + "/liff/campaignrewards/edit-product", data);
    return result.data;
  },
  async DeleteRewardToCart(data) {
    let result = await axios.delete(serverUrl + "/liff/campaignrewards/remove-product", {
      params: data,
    });
    return result.data;
  },
  async DeleteCart(data) {
    let result = await axios.delete(serverUrl + "/liff/campaignrewards/clear-carts", {
      params: data,
    });
    return result.data;
  },
  CheckExistingCartByCampaignId(params) {
    return axios.get(serverUrl + "/campaign/reward/user/cart", {
      params,
    });
  },
  async SaveRewardRequest(data) {
    return axios.post(serverUrl + "/campaign/reward/user/redeem", data);
  },
  GetRewardHistory(params) {
    return axios.get(serverUrl + "/campaign/reward/user/history", {
      params,
    });
  },
  GetLatestRewardCampaignRequest(data) {
    return axios.get(serverUrl + "/campaign/reward/user/history", { params: data });
  },
  CheckCampaignParticipationByCampaignIdAndUserId(params) {
    return axios.get(serverUrl + "/campaign/user/check-participation", { params });
  },
  async CheckOutOfStock(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/carts/product-out-of-stock", {
      params: data,
    });
    return result.data;
  },
  UpdatePersonalData(data) {
    return axios.put(serverUrl + "/member/" + data.user_id, data);
  },
  getParticipationCampaign(params) {
    return axios.get(serverUrl + "/campaign/user/participation", { params });
  },
  async FilloutLuckydrawRewardInfo(data) {
    let result = await axios.post(serverUrl + "/filloutluckydrawrewardinfo", data);
    return result.data;
  },
  async validateEcoupon(params) {
    const response = await axios.get(serverUrl + "/e-coupon/user/validate", { params });
    return response;
  },
  useEcoupon(params) {
    return axios.get(`${serverUrl}/e-coupon/user/used12312`, { params });
  },
};
