var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',[_c('div',{staticClass:"main-color pa-4 text-center white--text",staticStyle:{"font-size":"22px"}},[_vm._v(" ตรวจสอบรางวัล ")]),_c('div',{staticClass:"pa-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-left")]),_vm._v(" "),_c('span',{staticClass:"primary--text"},[_vm._v("กลับ")])],1),(_vm.rewards && _vm.rewards.length > 0)?_c('div',{staticClass:"mt-4"},_vm._l((_vm.rewards),function(r,index){return _c('div',{key:index,staticClass:"pa-2 box-reward"},[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('div',[_c('b',[_vm._v("แคมเปญ")]),_vm._v("   "+_vm._s(r.campaign_name))]),_c('div',{staticClass:"gray--text"},[_vm._v(" "+_vm._s(_vm.dayjs(r.timestamp).format("DD/MM/YYYY HH:mm"))+" ")])]),_vm._l((r.productdetails),function(p){return _c('div',{key:p.id,staticClass:"d-flex pl-5",staticStyle:{"height":"170px"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"170px","width":"160px"}},[_c('img',{attrs:{"src":p.photo,"width":"140px","height":"140px"}})]),_c('div',{staticClass:"pl-5 pt-10"},[_c('div',{staticClass:"pt-5 primary--text"},[_c('b',[_vm._v(_vm._s(p.name))])]),_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("แลก "+_vm._s(_vm.formattedNumber(p.points))+" แต้ม")])])])])}),_c('div',{staticClass:"mt-5 gray--text pl-7"},[_c('div',{class:{
            approve: ['จัดส่งแล้ว', 'รับสินค้าแล้ว'].includes(
              r.shipping_status
            ),
            waiting: !['จัดส่งแล้ว', 'รับสินค้าแล้ว'].includes(
              r.shipping_status
            ),
          }},[_vm._v(" สถานะ : "+_vm._s(r.shipping_status || "รอดำเนินการ")+" ")]),_c('div',[_vm._v("สาขา : "+_vm._s(_vm.findBranch(r.branch_id)))]),(r.shipping_status == 'รับสินค้าแล้ว')?_c('div',[_vm._v(" วันที่รับ : "+_vm._s(r.shipping_at ? _vm.dayjs(r.shipping_at).format("DD/MM/YYYY HH:mm") : "-")+" ")]):_vm._e(),(r.shipping_status == 'รับสินค้าแล้ว' && r.shipping_url)?_c('div',{on:{"click":function($event){return _vm.seeImg(r.shipping_url)}}},[_c('img',{staticStyle:{"width":"13px"},attrs:{"src":require("@/assets/search.svg")}}),_vm._v("  "),_c('a',[_vm._v("ดูรูปเพิ่มเติม")])]):_vm._e()])],2)}),0):_vm._e(),_c('infinite-loading',{attrs:{"spinner":"bubbles","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"})]),(_vm.rewards.length == 0 && _vm.IsstateComplete)?_c('div',{staticStyle:{"margin-top":"30%","text-align":"center"}},[_c('h2',{staticStyle:{"color":"#979696"}},[_vm._v("ยังไม่มีประวัติการแลกของรางวัล")])]):_vm._e(),_c('v-dialog',{model:{value:(_vm.showImageDialog),callback:function ($$v) {_vm.showImageDialog=$$v},expression:"showImageDialog"}},[_c('div',{on:{"click":function($event){_vm.showImageDialog = false}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.showImageDialogLink}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }