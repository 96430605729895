import axios from "axios";
const serverUrl = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTHORIZATION;
axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_API_KEY;

const instance = () =>
  axios.create({
    baseURL: serverUrl,
    timeout: 30000,
  });

export default {
  getPoolEcoupon(code) {
    return instance().get(`${serverUrl}/liff/e-coupon-pool-setting/${code}`);
  },
  getReceiveEcoupon(body) {
    return instance().post(`${serverUrl}/liff/receive-e-coupon`, body);
  },
  claimEcoupon(body) {
    return instance().post(`${serverUrl}/liff/claim-pool-e-coupon`, body);
  },
  getReceiveEcouponList(body) {
    return instance().post(`${serverUrl}/liff/receive-e-coupon-list`, body);
  },
  useEcoupon(body) {
    return instance().post(`${serverUrl}/liff/use-pool-e-coupon`, body);
  },
};
