import api from "@/api";
export default async function auth({ to, next, store }) {
  if (store.state.userData && store.state.userData.firstName) {
    return next();
  }

  store.commit("setLoading", true);
  let IsValid = await api.getProfile({
    user_id: store.state.userId,
    page_id: store.state.pageId,
  });
  store.commit("setLoading", false);
  if (!IsValid) {
    if (to.name === "poolEcoupon") {
      return next({ name: "register" });
    }
    window.liff.closeWindow();
    return next({ name: "none" });
  } else {
    return next();
  }
}
