<template>
  <MainLayout>
    <div
      style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; text-align: center; margin-top: 5%"
    >
      <div>
        <v-icon color="gray" x-large>mdi-note</v-icon>
        <div>ไม่พบข้อมูล</div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
export default {
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>
