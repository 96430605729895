<template>
  <div class="px-8" v-if="poolData">
    <div style="text-align: center;" class="flex-all-center py-7">
      <div style="width: 100%">
        <img :src="poolData.img_url" width="100%" />
      </div>
    </div>
    <h4 class="text-primary">ชื่อคูปอง</h4>
    <p>{{ poolData.name }}</p>
    <h4 class="text-primary">เงื่อนไขการใช้งาน</h4>
    <p>{{ poolData.condition_message }}</p>
    <h4 class="text-primary">มูลค่า</h4>
    <p>{{ formattedNumber(poolData.prize_value) }} บาท</p>
    <h4 class="text-primary">ระยะเวลาในการกดรับคูปอง</h4>
    <p>
      {{ dayjs(poolData.start_receive_date).format("DD/MM/YYYY HH:mm") }} -
      {{ dayjs(poolData.end_receive_date).format("DD/MM/YYYY HH:mm") }}
    </p>
    <h4 class="text-primary">ระยะเวลาในการใช้คูปอง</h4>
    <p>
      {{ dayjs(poolData.start_using_date).format("DD/MM/YYYY HH:mm") }} -
      {{ dayjs(poolData.end_using_date).format("DD/MM/YYYY HH:mm") }}
    </p>

    <slot class="my-5"></slot>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: ["poolData"],
  data() {
    return {
      loading: false,
      topicHeader: "E-COUPON",
    };
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  async mounted() {},
  methods: {},
};
</script>
