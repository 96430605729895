export default {
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    userData() {
      return this.$store.state.userData;
    },
    pageId() {
      return this.$store.state.pageId;
    },
  },
  methods: {
    formattedNumber(number = 0) {
      return (+number || 0).toLocaleString();
    },
  },
};
