<template>
  <div>
    <div
      v-for="(data, index) in couponList"
      :key="`recevice-eecoupon-${index + 1}`"
      class="py-5"
      style="box-shadow: 5px 5px #e6e6e6"
    >
      <v-layout row wrap class="ma-0" @click="handleClickReceiveEcoupon(data)">
        <v-flex xs6>
          <div style="text-align: center" class="flex-all-center px-3">
            <div style="width: 100%">
              <img :src="data.pool_data[0].img_url" width="100%" />
            </div>
          </div>
        </v-flex>
        <v-flex xs6>
          <h4 class="text-primary">{{ data.pool_data[0].name }}</h4>
          <div>
            <span class="text-primary">สถานะ : </span
            >{{ data.is_used ? "ใช้คูปองแล้ว" : "ยังไม่ได้ใช้คูปอง" }}
          </div>
          <div>
            <span class="text-primary">วันที่รับ : </span
            >{{
              data.timestamp
                ? dayjs(data.timestamp).format("DD/MM/YYYY HH:mm")
                : "-"
            }}
          </div>
          <div>
            <span class="text-primary">วันที่ใช้ : </span
            >{{
              data.used_timestamp
                ? dayjs(data.used_timestamp).format("DD/MM/YYYY HH:mm")
                : "-"
            }}
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog
      v-model="showDetail"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <div
          class="main-color pa-4 text-center white--text"
          style="font-size: 22px"
        >
          {{ topicHeader }}
        </div>
        <div class="pa-4" style="cursor: pointer" @click="showDetail = false">
          <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span
            class="primary--text"
            >กลับ</span
          >
        </div>
        <PoolEcouponDetail
          v-if="selectData && selectData.pool_data && selectData.pool_data[0]"
          :poolData="selectData.pool_data[0]"
        >
          <div v-if="isOnTime">
            <div style="color: red">กรุณาเลือกสาขาก่อนกดใช้คูปอง *</div>
            <ValidationObserver ref="observer">
              <ValidationProvider
                rules="required"
                name="สาขา"
                v-slot="{ errors }"
              >
                <v-select
                  :error-messages="errors"
                  v-model="selectBranch"
                  outlined
                  :items="branchData"
                  item-value="id"
                  item-text="name"
                ></v-select>
              </ValidationProvider>
              <div class="flex-all-center" style="width: 100%">
                <v-btn
                  large
                  color="primary"
                  style="width: 100%"
                  @click="handleUseEcoupon"
                  :loading="useLoading"
                  >ใช้คูปอง</v-btn
                >
              </div>
            </ValidationObserver>
          </div>
          <div v-else>
            <div style="color: red; text-align: center">
              คูปองอยู่นอกเวลาการใช้งาน
            </div>
          </div>
        </PoolEcouponDetail>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reCheckUseEcoupon" persistent>
      <v-card class="pa-5">
        <h3 class="text-primary" style="text-align: center">
          ท่านต้องการยืนยันการใช้งานคูปองหรือไม่
        </h3>
        <div class="flex-all-center mt-5" style="width: 100%">
          <v-btn
            large
            color="primary"
            style="width: 100%"
            @click="handleConfirmUseEcoupon"
            :loading="useLoading"
            >ตกลง</v-btn
          >
        </div>
        <div class="flex-all-center mt-2" style="width: 100%">
          <v-btn
            large
            style="width: 100%"
            @click="reCheckUseEcoupon = false"
            :loading="useLoading"
            >ยกเลิก</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUseComplete" persistent>
      <v-card class="px-8 py-8" v-if="showUseComplete && selectData">
        <div style="text-align: center" class="flex-all-center py-7">
          <div style="width: 30%">
            <img src="@/assets/correct.png" width="100%" />
          </div>
        </div>
        <h3 class="text-primary" style="text-align: center">
          คูปองถูกใช้เรียบร้อยแล้ว
        </h3>
        <h4 class="text-primary mt-4">ชื่อคูปอง</h4>
        <p>{{ selectData.pool_data[0].name }}</p>
        <h4 class="text-primary">เงื่อนไขการใช้งาน</h4>
        <p>{{ selectData.pool_data[0].condition_message }}</p>
        <h4 class="text-primary">มูลค่า</h4>
        <p>
          {{
            formattedNumber(
              selectData.pool_data[0] ? selectData.pool_data[0].prize_value : 0
            )
          }}
          บาท
        </p>
        <h4 class="text-primary">สาขาที่ใช้</h4>
        <p>{{ getBranchNameFromId(selectBranch) }}</p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import poolEcouponApi from "@/api/poolEcoupon";
import PoolEcouponDetail from "./PoolEcouponDetail";
import dayjs from "dayjs";
import api from "@/api";

export default {
  components: { PoolEcouponDetail },
  props: ["campaign"],
  data() {
    return {
      couponList: [],
      showDetail: false,
      topicHeader: "E-COUPON",
      selectData: null,
      branchData: [],
      selectBranch: null,
      useLoading: false,
      reCheckUseEcoupon: false,
      showUseComplete: false,
    };
  },
  computed: {
    dayjs() {
      return dayjs;
    },
    isOnTime() {
      if (
        this.selectData &&
        this.selectData.start_using_date &&
        this.selectData.end_using_date
      ) {
        try {
          const diffStart = dayjs().diff(
            dayjs(this.selectData.start_using_date)
          );
          const diffEnd = dayjs(this.selectData.end_using_date).diff(dayjs());
          if (diffStart < 0 || diffEnd < 0) {
            return false;
          } else {
            return true;
          }
        } catch (error) {
          console.error(error);
          return false;
        }
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.setup();
  },
  methods: {
    async setup() {
      await this.getBranch();
      const response = await poolEcouponApi.getReceiveEcouponList({
        user_id: this.userId,
        page_id: this.pageId,
        limit: 99999,
        offset: 0,
      });
      if (response && response.data && response.data.data) {
        const tempData = response.data.data.filter((receiveEcoupon) => {
          return receiveEcoupon.pool_data && receiveEcoupon.pool_data.length;
        });
        this.couponList = tempData;
      } else this.couponList = [];
    },
    handleClickReceiveEcoupon(data) {
      if (data.is_used) {
        this.$swal.fire({
          text: "คูปองนี้ถูกใช้เรียบร้อยแแล้ว",
        });
      } else {
        this.selectData = data;
        this.showDetail = true;
      }
    },
    async getBranch() {
      this.$store.commit("setLoading", true);
      let data = {
        page_id: this.pageId,
        campaign_id: this.campaign.id,
        limit: 99999,
        offset: 0,
      };
      try {
        let result = await api.getBranch(data);
        this.branchData = result.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    async handleUseEcoupon() {
      let validateData = await this.$refs.observer.validate();
      if (validateData) {
        this.reCheckUseEcoupon = true;
      }
    },
    async handleConfirmUseEcoupon() {
      this.reCheckUseEcoupon = false;
      let validateData = await this.$refs.observer.validate();
      if (validateData) {
        try {
          this.useLoading = true;
          await poolEcouponApi.useEcoupon({
            id: this.selectData.id,
            user_id: this.userId,
            page_id: this.pageId,
            used_branch_id: this.selectBranch,
          });
          this.showUseComplete = true;
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.$swal.fire({
              type: "error",
              text: error.response.data.message,
            });
          } else {
            console.error(error);
            this.$swal.fire({
              type: "error",
              text: `ใช้คูปองไม่สำเร็จ`,
            });
          }
        } finally {
          this.useLoading = false;
        }
      }
    },
    getBranchNameFromId(id) {
      const target = this.branchData.find((item) => item.id === id);
      if (!target) return "-";
      return target.name;
    },
  },
};
</script>
