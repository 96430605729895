<template>
  <MainLayout>
    <div>
      <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
        {{ topicHeader }}
      </div>
      <PoolEcouponDetail :poolData="poolData" v-if="ecouponState == 'detail'">
        <div class="flex-all-center mt-10" style="width: 100%;">
          <v-btn large color="primary" style="width: 100%;" @click="handleClaimEcoupon" :loading="claimLoading">กดรับคูปอง</v-btn>
        </div>
      </PoolEcouponDetail>
      <NotiStatusEcoupon message="คูปองนี้หมดอายุแล้ว" v-else-if="ecouponState == 'soldout'" />
      <NotiStatusEcoupon message="คูปองนี้ถูกใช้แล้ว" v-else-if="ecouponState == 'used'" />
    </div>

    <v-dialog v-model="showClaimed" persistent>
      <v-card class="px-8 py-8">
        <div style="text-align: center;" class="flex-all-center py-7">
          <div style="width: 30%">
            <img src="@/assets/correct.png" width="100%" />
          </div>
        </div>
        <h3 class="text-primary" style="text-align: center;">กดรับคูปองเรียบร้อยแล้ว</h3>
        <p style="text-align: center;">สามารถกดใช้คูปองได้ที่หน้าเมนู “ข้อมูลสมาชิก” จากนั้นกดที่ “ดูประวัติการแลกของรางวัล”</p>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import PoolEcouponDetail from "./PoolEcoupon/PoolEcouponDetail.vue";
import NotiStatusEcoupon from "./PoolEcoupon/NotiStatusEcoupon.vue";
import poolEcouponApi from "@/api/poolEcoupon";
import dayjs from "dayjs";

export default {
  components: {
    PoolEcouponDetail,
    NotiStatusEcoupon,
  },
  data() {
    return {
      loading: false,
      claimLoading: false,
      topicHeader: "E-COUPON",
      ecouponState: "detail", // detail soldout used
      poolData: null,
      showClaimed: false,
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
  },
  async mounted() {
    this.setup();
  },
  methods: {
    async setup() {
      try {
        this.loading = true;
        const responsePool = await poolEcouponApi.getPoolEcoupon(this.code);

        if (responsePool.data && responsePool.data.data) {
          this.poolData = responsePool.data.data;

          const responseReceiveEcoupon = await poolEcouponApi.getReceiveEcoupon({
            user_id: this.userId,
            page_id: this.pageId,
            pool_setting_id: this.poolData.id,
          });

          if (responseReceiveEcoupon.data && responseReceiveEcoupon.data.data && responseReceiveEcoupon.data.data.id) {
            if (responseReceiveEcoupon.data.data.is_used) {
              this.ecouponState = "used";
            } else {
              this.$router.push({
                name: "reward",
                query: { ...this.$route.query, type: "ecoupon" },
              });
            }
          }

          if (this.poolData.start_receive_date && this.poolData.end_receive_date) {
            try {
              const diffStart = dayjs().diff(dayjs(this.poolData.start_receive_date));
              const diffEnd = dayjs(this.poolData.end_receive_date).diff(dayjs());
              if (diffStart < 0 || diffEnd < 0) {
                // out of time
                this.ecouponState = "soldout";
              }
            } catch (error) {
              console.error(error);
              this.ecouponState = "soldout";
            }
          }
        } else {
          throw new Error("No data response");
        }
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          type: "error",
          text: `เรียกข้อมูลไม่สำเร็จ`,
        });
      } finally {
        this.loading = false;
      }
    },
    async handleClaimEcoupon() {
      console.log("handleClaimEcoupon");
      try {
        this.claimLoading = true;
        const body = {
          pool_setting_id: this.poolData.id,
          user_id: this.userId,
          page_id: this.pageId,
        };
        await poolEcouponApi.claimEcoupon(body);
        this.showClaimed = true;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.$swal.fire({
            type: "error",
            text: error.response.data.message,
          });
        } else {
          console.error(error);
        }
      } finally {
        this.claimLoading = false;
      }
    },
  },
};
</script>
