<template>
  <div
    style="height: 100%; max-width: 500px; background: white;"
    class="mx-auto"
  >
    <v-main><slot></slot></v-main>
    <v-bottom-navigation
      app
      v-if="showBottomNav"
      fixed
      x-large
      v-model="tab"
      :height="80"
      style="
        bottom: -2px;
        max-width: 500px;
        left: 50%;
        transform: translateX(-50%);
      "
    >
    <!-- to="campaign" -->
      <v-btn
        :active-class="!IsClickReward ? 'thrid-color': ''"
        value="campaign"
        height="100%"
        width="50%"
        class="pa-3"
        max-width="100%"
         @click="goToPage('campaign')"
        style="z-index: 2 !importment; float: left;"
      >
        <img src="@/assets/bill.png" height="30px" width="30px" class="ma-1" />
        <span class="ma-1">แนบใบเสร็จ</span>
      </v-btn>

      <div
        @click="goToPage('productReward')"
        :class="{ 'thrid-color': IsClickReward }"
        style="position: absolute;  left: 50%;
        transform: translateX(-50%); bottom: 1px; height: 105px; z-index: 1; !importment; background: white; border-radius: 50%; padding: 10px; text-align: center; border: 1px solid #f5f5f0;"
      >
        <div class="ma-1" style="font-size: 12px; padding-top: 10px;">
          แลกของรางวัล
        </div>
        <img src="@/assets/giftBox.png" height="30px" width="30px" />
      </div>

      <v-btn
        :active-class="!IsClickReward ? 'thrid-color': ''"
        class="pa-3"
        value="profile"
        height="100%"
        max-width="100%"
        width="50%"
        @click="goToPage('profile')"
        style="z-index: 2 !importment; float: right;"
      >
        <img src="@/assets/user.png" height="30px" width="30px" class="ma-1" />
        <span class="ma-1">สมาชิก</span>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  props: {
    showBottomNav: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      tab: this.$route.name,
    };
  },
  computed:{
    IsClickReward() {
      return this.$store.state.IsClickReward;
    }
  },
  methods: {
    goToPage(page) {
      if (this.$route.name != page) {
        if (page == "productReward") {
          this.$store.state.IsClickReward = true;
        }else{
          this.$store.state.IsClickReward = false;
        }
        this.$router.push({ name: page });
      }
    },
  },
};
</script>
