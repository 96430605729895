<template>
  <MainLayout>
    <div class="pa-4 text-center white--text main-color" style="font-size: 22px">
      นโยบายความเป็นส่วนตัว
    </div>
    <div class="pa-4 d-flex justify-space-between" @click="onBack" style="cursor: pointer;">
      <div>
        <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
      </div>
      <div>
        <a
          style="color: inherit;text-decoration: none;"
          href="https://toaecoupon.zwiz.ai/customer_consent.pdf"
          >ดาวน์โหลด</a
        >
      </div>
    </div>
    <div class="ma-2 pa-4" style="background-color: #EEEEEE; border-radius: 5px;">
      <b>ข้อกำหนดและเงื่อนไข</b>
      <ul class="topic">
        <li>
          <b>1. ประเภทข้อมูลส่วนบุคคล</b>
          <ul>
            <li>
              (1) ข้อมูลทั่วไป หมายความว่า
              ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
              แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ ตามที่กำหนดไว้ใน<span
                ><b>นโยบายความเป็นส่วนตัวสำหรับลูกค้าของทีโอเอ</b></span
              >
            </li>
            <li>
              (2) ข้อมูลส่วนบุคคลที่มีความอ่อนไหว หมายความว่า ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ
              เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ
              ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
              หรือข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
              และตามที่กำหนดไว้ในนโยบายความเป็นส่วนตัวสำหรับลูกค้าของทีโอเอ
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>2. วัตถุประสงค์การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</b>
          <ul>
            <li>
              (1) ข้าพเจ้าให้ความยินยอมแก่บริษัท ในการเก็บรวบรวม ใช้ และ/หรือ
              เปิดเผยข้อมูลส่วนบุคคลเพื่อประโยชน์ในการติดต่อสื่อสาร การนัดหมาย
            </li>
            <li>
              (2)
              เพื่อใช้ในการดำเนินการตามคำขอของข้าพเจ้าก่อนเข้าทำสัญญาหรือเพื่อปฏิบัติตามสัญญาซึ่งข้าพเจ้าเป็นคู่สัญญากับบริษัทฯ
              เช่น เพื่อการขายสินค้า และ/หรือ บริการให้แก่ข้าพเจ้า หรือปฏิบัติตามสัญญาใด ๆ
              ซึ่งข้าพเจ้าเป็นคู่สัญญา ซึ่งรวมถึง การจัดการ account ของข้าพเจ้า จัดส่ง
              การดำเนินการเกี่ยวกับบัญชีและการเงิน การบริการหลังการขาย และการคืนสินค้า
              และดำเนินการใด ๆ เพื่อให้ข้าพเจ้าได้รับสินค้าและ/หรือ บริการ
              หรือตามที่ข้าพเจ้าได้ร้องขอ
              ตลอดจนกรณีที่ข้าพเจ้าได้เข้าร่วมกิจกรรมการตลาดต่างๆกับบริษัท เช่น การประกวด แข่งขัน
              ชิงรางวัล การสะสมแต้มเพื่อแลก และ/หรือชิงรางวัล
              บริษัทสามารถใช้ข้อมูลของข้าพเจ้าเพื่อเป็นข้อมูลประกอบการร่วมกิจกรรม และการพิจารณา
              ตัดสิน และเป็นการยืนยันตัวตนในการแลก รับ ของรางวัล
            </li>
            <li>
              (3) เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือของบุคคลอื่น
              <ul>
                <li>
                  • เพื่อให้บริษัทฯ สามารถจัดการ พัฒนา และดำเนินการใด ๆ
                  เพื่อให้สามารถดำเนินธุรกิจได้ ซึ่งรวมถึง การบริหารจัดการและพัฒนาสินค้า และ/หรือ
                  บริการ (รวมถึงเว็บไซต์ และแอปพลิเคชัน) การตรวจสอบและป้องกันการฉ้อโกง
                  หรืออาชญากรรมอื่น ๆ การจัดการความสัมพันธ์ลูกค้าและผู้ที่อาจเป็นลูกค้า
                  การบำรุงรักษาและการใช้ระบบไอที
                </li>
                <li>
                  • เพื่อประโยชน์ทางด้านความปลอดภัย เช่น จัดให้มีมาตรการรักษาความปลอดภัย
                  ซึ่งรวมถึงข้อมูลส่วนบุคคลของข้าพเจ้าด้วย เช่น การเข้าสถานที่ของบริษัทฯ การ Log in
                  เข้าเว็บไซต์หรือแอปพลิเคชัน
                </li>
                <li>
                  • เพื่อดำเนินการทางการตลาด และวิเคราะห์ข้อมูล (Marketing and Data Analysis) เช่น
                  การแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทางอีเมล เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย
                  โทรศัพท์รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม เข้าสัมภาษณ์
                </li>
                <li>
                  • เพื่อการใช้สิทธิเรียกร้องทางกฎหมายที่เกี่ยวข้องกับบริษัทฯ และ/หรือ ข้าพเจ้า
                </li>
              </ul>
            </li>
            <li>
              (4) เพื่อป้องกันและระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของข้าพเจ้าหรือบุคคลอื่นเช่น
              การติดต่อในกรณีฉุกเฉิน การควบคุมและป้องกันโรคติดต่อ
            </li>
            <li>
              (5) เพื่อให้สามารถปฏิบัติตามกฎหมาย เช่น การปฏิบัติตามบทบัญญัติของกฎหมาย กฏระเบียบ
              และคำสั่งของผู้ที่มีอำนาจตามกฎหมาย
            </li>
            <li>
              (6) เพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ
              หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้
            </li>
            <li>
              (7) ข้าพเจ้าให้ความยินยอมแก่บริษัท บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคล
            </li>
            <li>
              (8) เพื่อให้บริษัทในกลุ่มบริษัทฯ
              ที่ไม่ใช่ผู้ขายสินค้าและ/บริการข้าพเจ้ามาก่อนสามารถติดต่อสื่อสารกับข้าพเจ้า
              เพื่อการแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทาง อีเมล เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย
              โทรศัพท์ รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม เข้าสัมภาษณ์
              เพื่อใช้ในการวิเคราะห์พฤติกรรมการบริโภคของลูกค้า เพื่อนำไปพัฒนาโปรแกรม
              หรือโปรโมชั่นทางการตลาด
              <ul>
                <li>
                  • เพื่อให้บริษัทฯ ดำเนินการจัดกิจกรรมนอกจากที่กล่าวมาข้างต้น
                  โดยอาจเก็บข้อมูลส่วนบุคคลของข้าพเจ้าเพิ่มเติม
                  โดยจะแจ้งให้ทราบและขอความยินยอมจากข้าพเจ้าใหม่เป็นคราว ๆ ไป
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>3. ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคล</b>
          <ul>
            <li>
              (1) บริษัทฯ
              จะเก็บข้อมูลส่วนบุคคลของข้าพเจ้าในระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ตามประเภทข้อมูลส่วนบุคคลแต่ละประเภท
              เว้นแต่กฎหมายจะอนุญาตให้มีระยะเวลาการเก็บรักษาที่นานขึ้น
              ในกรณีที่ไม่สามารถระบุระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคลได้ชัดเจน บริษัทฯ
              จะเก็บรักษาข้อมูลไว้ตามระยะเวลาที่อาจคาดหมายได้ตามมาตรฐานของการเก็บรวบรวม (เช่น
              อายุความตามกฎหมายทั่วไปสูงสุด 10 ปี)
            </li>
            <li>
              (2) บริษัทฯ
              จัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนดระยะเวลาการเก็บรักษาหรือที่ไม่เกี่ยวข้องหรือเกินความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลนั้น
              บริษัทฯ
              จัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนดระยะเวลาการเก็บรักษาหรือที่ไม่เกี่ยวข้องหรือเกินความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลนั้น
            </li>
            <li>
              (3) กรณีที่บริษัทฯ ใช้ข้อมูลส่วนบุคคลของข้าพเจ้าโดยขอความยินยอมจากข้าพเจ้า บริษัทฯ
              จะประมวลผลข้อมูลส่วนบุคคลดังกล่าวจนกว่าข้าพเจ้าจะแจ้งขอยกเลิกความยินยอมและบริษัทฯ
              ดำเนินการตามคำขอของข้าพเจ้าเสร็จสิ้นแล้ว อย่างไรก็ดี บริษัทฯ
              จะยังเก็บข้อมูลส่วนบุคคลของข้าพเจ้าเท่าที่จำเป็นสำหรับบันทึกเป็นประวัติว่าข้าพเจ้าเคยยกเลิกความยินยอม
              เพื่อให้ บริษัทฯ สามารถตอบสนองต่อคำขอของข้าพเจ้าในอนาตได้
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>4. การเปิดเผยข้อมูลส่วนบุคคล</b
          ><br />เราเปิดเผยและแบ่งปันข้อมูลส่วนบุคคลของลูกค้ากับบริษัทในกลุ่มทีโอเอตามเอกสารแนบท้าย
          และบริษัทในกลุ่มพันธมิตร และ
          <ul>
            <li>
              (1) บุคคลและนิติบุคคลอื่นที่ไม่ได้เป็นบริษัทในกลุ่มของเรา (“บุคคลอื่น”)
              เพื่อบรรลุวัตถุประสงค์การเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
              เช่น ผู้ให้บริการเกี่ยวกับกระบวนการสรรหาและคัดเลือกบุคคลากร การจ้างงาน
              การรักษาความปลอดภัย การตรวจสอบประวัติ การทดสอบคุณสมบัติและความสามารถ ระบบสารสนเทศ
              สถาบันการเงิน คู่ธุรกิจ ผู้พัฒนาระบบสารสนเทศ หน่วยงานของรัฐ
              และบุคคลอื่นที่จำเป็นเพื่อให้เราสามารถดำเนินธุรกิจและให้บริการแก่ลูกค้า
              รวมถึงดำเนินการใด ๆ
              ตามวัตถุประสงค์การเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้บริษัทฯ
              จะกำหนดให้ผู้ที่ได้รับข้อมูลมีมาตรการปกป้องข้อมูลของข้าพเจ้าอย่างเหมาะสมและประมวลผลข้อมูลส่วนบุคคลดังกล่าวเท่าที่จำเป็นเท่านั้น
              และดำเนินการเพื่อป้องกันไม่ให้บุคคลอื่นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจโดยมิชอบ
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>5. การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</b>
          <ul>
            <li>
              (1) บริษัทฯ
              อาจส่งหรือโอนข้อมูลส่วนบุคคลของข้าพเจ้าไปยังบริษัทในเครือหรือบุคคลอื่นในต่างประเทศในกรณีที่จำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งข้าพเจ้าเป็นคู่สัญญา
              หรือเป็นการกระทำตามสัญญาระหว่างบริษัทฯ
              กับบุคคลหรือนิติบุคคลอื่นเพื่อประโยชน์ของข้าพเจ้าหรือเพื่อใช้ในการดำเนินการตามคำขอของข้าพเจ้าก่อนเข้าทำสัญญา
              หรือเพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของข้าพเจ้าหรือบุคคลอื่น
              เพื่อปฏิบัติตามกฎหมาย หรือเป็นการจำเป็นเพื่อดำเนินภารกิจเพื่อประโยชน์สาธารณะที่สำคัญ
            </li>
            <li>
              (2) บริษัทฯ
              อาจเก็บข้อมูลของข้าพเจ้าบนคอมพิวเตอร์เซิร์ฟเวอร์หรือคลาวด์ที่ให้บริการโดยบุคคลอื่น
              และอาจใช้โปรแกรมหรือแอปพลิเคชันของบุคคลอื่นในรูปแบบของ Software as a Service (SaaS)
              และ Platform as a Service (PaaS) ในการประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้า แต่บริษัทฯ
              จะไม่อนุญาตให้บุคคลที่ไม่เกี่ยวข้องสามารถเข้าถึงข้อมูลส่วนบุคคลได้
              และจะกำหนดให้บุคคลอื่นเหล่านั้นต้องมีมาตรการคุ้มครองความมั่นคงปลอดภัยที่เหมาะสม
            </li>
            <li>
              (3) ในกรณีที่มีการส่งข้อมูลส่วนบุคคลของข้าพเจ้าไปยังต่างประเทศ บริษัทฯ
              จะใช้มาตรการที่เหมาะสมเพื่อทำให้มั่นใจได้ว่าข้อมูลส่วนบุคคลของข้าพเจ้าจะได้รับการคุ้มครองและข้าพเจ้าสามารถใช้สิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของข้าพเจ้าได้ตามกฎหมาย
              รวมถึงบริษัทฯ
              จะกำหนดให้ผู้ที่ได้รับข้อมูลมีมาตรการปกป้องข้อมูลของข้าพเจ้าอย่างเหมาะสมและประมวลผลข้อมูลส่วนบุคคลดังกล่าวเท่าที่จำเป็นเท่านั้น
              และดำเนินการเพื่อป้องกันไม่ให้บุคคลอื่นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจโดยมิชอบ
            </li>
            <br />
            <li>
              บริษัทอาจจะส่งหรือโอนข้อมูลส่วนบุคคล ที่บริษัทเก็บรวบรวม ใช้ หรือเปิดเผย
              ไปยังต่างประเทศ
              ซึ่งประเทศปลายทางหรือองค์การระหว่างประเทศที่รับข้อมูลส่วนบุคคลต้องมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอตามหลักเกณฑ์ที่กฎหมายกำหนด
              และตามนโยบายความเป็นส่วนตัวสำหรับลูกค้าของทีโอเอ
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>6. สิทธิของเจ้าของข้อมูลส่วนบุคคล</b>
          <ul>
            <li>
              (1)
              ถอนความยินยอมที่ลูกค้าข้าพเจ้าได้ให้ไว้กับเราเพื่อประมวลผลข้อมูลส่วนบุคคลของลูกค้าข้าพเจ้า
            </li>
            <li>
              (2) ขอดูและคัดลอกข้อมูลส่วนบุคคลของลูกค้าข้าพเจ้า
              หรือขอให้เปิดเผยที่มาของข้อมูลส่วนบุคคลของลูกค้าข้าพเจ้า
            </li>
            <li>
              (3)
              ส่งหรือโอนข้อมูลส่วนบุคคลที่อยู่ในรูปแบบอิเล็กทรอนิกส์ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนดไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
            </li>
            <li>
              (4) คัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับลูกค้าข้าพเจ้า
            </li>
            <li>
              (5) ลบหรือทำลาย
              หรือทำให้ข้อมูลส่วนบุคคลของลูกค้าข้าพเจ้าเป็นข้อมูลที่ไม่สามารถระบุตัวตนของลูกค้าข้าพเจ้าได้
              (anonymous)
            </li>
            <li>(6) ระงับการใช้ข้อมูลส่วนบุคคลของลูกค้าข้าพเจ้า</li>
            <li>
              (7) แก้ไขข้อมูลส่วนบุคคลของลูกค้าข้าพเจ้าให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์
              และไม่ก่อให้เกิดความเข้าใจผิด
            </li>
            <li>
              (8) ร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
              ในกรณีที่เราหรือผู้ประมวลผลข้อมูลส่วนบุคคล
              รวมทั้งลูกจ้างหรือผู้รับจ้างของเราหรือผู้ประมวลผลข้อมูลส่วนบุคคลฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>7. ผลการเพิกถอนความยินยอม</b>
          <ul>
            <li>
              (1)
              ในกรณีที่เราเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลโดยอาศัยความยินยอมของลูกค้าข้าพเจ้า
              ลูกค้าข้าพเจ้ามีสิทธิที่จะถอนความยินยอมของลูกค้าข้าพเจ้าที่ให้ไว้กับเราได้ตลอดเวลา
              ซึ่งการถอนความยินยอมนี้จะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ เปิดเผย
              หรือประมวลผลข้อมูลส่วนบุคคลที่ลูกค้าข้าพเจ้าได้ให้ความยินยอมไปแล้ว
            </li>
            <li>
              (2) หากลูกค้าข้าพเจ้าถอนความยินยอมที่ได้ให้ไว้กับเราหรือปฏิเสธไม่ให้ข้อมูลบางอย่าง
              อาจส่งผลให้เราไม่สามารถดำเนินการเพื่อบรรลุวัตถุประสงค์บางส่วนหรือทั้งหมดตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ได้
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>8. ช่องทางติดต่อ</b>
          <ul>
            <li>
              (1) ผู้ควบคุมข้อมูลส่วนบุคคล: บริษัท ทีโอเอ เพ้นท์ (ประเทศไทย) จำกัด (มหาชน)
              สถานที่ติดต่อ: 31/2 หมู่ 3 ถนนบางนา-ตราด ตำบลบางเสาธง อำเภอ บางเสาธง จังหวัด
              สมุทรปราการ 10570
            </li>
            <li>
              (2) กรณีที่ข้าพเจ้ามีข้อสอบถามเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล โปรดติดต่อเบอร์
              02-335-5555 หรือ <span><b>e-mail: Data.Privacy@toagroup.com</b></span>
            </li>
          </ul>
        </li>
        <br />
        <li>
          ข้าพเจ้าได้อ่านรับทราบและเข้าใจข้อความเกี่ยวกับการเก็บรวบรวม ใช้
          และ/หรือเปิดเผยข้อมูลส่วนบุคคล
          ในหนังสือฉบับนี้โดยตลอดแล้วเห็นว่าถูกต้องตรงตามเจตนาและความประสงค์ของข้าพเจ้าทุกประการ
          รวมทั้ง ข้าพเจ้ายินยอมให้บริษัทดำเนินการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล
          ตามรายละเอียดที่ระบุข้างต้น
        </li>
      </ul>
    </div>
  </MainLayout>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    onBack() {
      this.$router.push({ name: "register" });
    },
  },
};
</script>

<style scoped>
.topic {
  padding-left: 0px !important;
}
ul li {
  list-style: none;
}
</style>
