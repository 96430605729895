<template>
  <div class="flex-all-center full-height-with-nav">
    <div>
      <div style="text-align: center; display: flex;justify-content: center;align-items: center;">
        <div style="width: 100px">
          <img src="@/assets/ecoupon.png" width="100%" />
        </div>
      </div>
      <h3 style="text-align: center;" class="text-primary">{{ message }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
};
</script>
