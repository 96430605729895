<template>
  <div class="upload-multiple-image">
    <v-layout wrap fill-height>
      <v-flex v-if="images.length < limit" style="cursor: pointer; height: 100px">
        <div
          :class="{
            'btn-upload': true,
            'border-input': true,
            errorInput: errors && errors.length > 0,
            disabledInput: disabledInput,
          }"
          @click="onClickUploadEvent"
          @dragover.prevent
          @drop.prevent="onFileChange($event)"
        >
          <v-responsive>
            <v-row column justify="center" fill-height align="center">
              <v-col class="pt-1" v-if="!loading">
                <div
                  :class="{
                    'text-center': true,
                    normalInput: errors.length == 0,
                    errorInput: errors && errors.length,
                    disabledInput: disabledInput,
                  }"
                >
                  <v-icon
                    large
                    class="mt-3"
                    :class="{
                      normalInput: errors.length == 0,
                      errorInput: errors && errors.length > 0,
                      disabledInput: disabledInput,
                    }"
                    >camera_alt</v-icon
                  >
                  <br />{{ label ? label : "เลือกไฟล์เพื่ออัปโหลด" }}
                </div>
              </v-col>
              <v-col v-else class="text-center">
                <v-progress-circular indeterminate color="primary" size="30"></v-progress-circular>
              </v-col>
            </v-row>
          </v-responsive>
        </div>
      </v-flex>

      <v-flex xs12 v-if="errors && errors.length > 0">
        <div class="v-messages">
          <div class="error--text" style="font-size: 15px; color: #b71c1c">
            {{ errors[0] }}
          </div>
        </div>
      </v-flex>
    </v-layout>

    <div class="form-control has-error" v-if="isAlert">
      <span class="text-error">{{ "Please try to upload file again" }}</span>
    </div>
    <input
      :disabled="disabled"
      type="file"
      :multiple="multiple"
      class="form-control-file pt-2"
      name="photoUpload"
      ref="photoUpload"
      data-vv-as="File"
      accept="image/*;capture=camera"
      :capture="opencamera"
      @change="onFileChange($event)"
      style="
        overflow: hidden;
        position: fixed;
        width: 1px;
        height: 1px;
        z-index: -1;
        opacity: 0;
      "
    />
    <div v-if="showpreview" class="mt-3">
      <v-row>
        <v-col @mouseover="hover = k" @mouseleave="hover = ''" v-for="(item, k) in value" :key="k" cols="3" class="pr-1">
          <div style="position: relative">
            <v-img @click="showPreview(item)" style="border-radius: 6px" aspect-ratio="1" :src="item" @error="() => (item = null)">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div style="position: absolute; right: -5px; top: -5px; z-index: 1">
              <v-btn @click.stop="deleteImg(k)" x-small fab color="primary"><v-icon small>close</v-icon></v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog width="550px" v-model="previewDialog" persistent>
      <v-card v-if="previewDialog">
        <div class="text-right">
          <v-btn @click="previewDialog = false" icon><v-icon>close</v-icon></v-btn>
        </div>
        <v-img :src="currentImg"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UpdateFileMixin from "./UpdateFileMixin.js";
export default {
  mixins: [UpdateFileMixin],
  props: {
    opencamera: {
      default: false,
      type: Boolean,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    showpreview: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      previewDialog: false,
      currentImg: "",
    };
  },
  methods: {
    onClickUploadEvent() {
      this.$refs.photoUpload.click();
    },
    showPreview(item) {
      this.currentImg = item;
      this.previewDialog = true;
    },
  },
  computed: {
    disabledInput() {
      return this.images.length == this.limit;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-upload {
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
.border-input {
  border-radius: 10px;
  border: 2px dashed #e5e5e5;
  display: inline-table;
  width: 100%;
  height: 100%;
  padding-top: 13px;
}
.errorInput {
  border-color: red !important;
  color: red !important;
}
.disabledInput {
  border-color: gray !important;
  color: gray !important;
}
.normalInput {
  color: #00626a !important;
  border-color: #00626a !important;
}
</style>
