<template>
  <MainLayout>
    <div class="pa-4 text-center white--text main-color" style="font-size: 22px">
      นโยบายความเป็นส่วนตัว
    </div>
    <div class="pa-4" @click="$router.go(-1)" style="cursor: pointer">
      <div><v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span></div>
    </div>
    <div class="ma-2 pa-4" style="background-color: #eeeeee; border-radius: 5px">
      <div class="text-center">
        <b>นโยบายความเป็นส่วนตัวสำหรับลูกค้า ผู้เข้าร่วมกิจกรรม ของบริษัท ทีโอเอ เพ้นท์ (ประเทศไทย) จำกัด (มหาชน)</b>
      </div>
      <ul class="topic">
        <li>
          <b>1. บทนำ</b>
          <ul>
            <li>
              บริษัท ทีโอเอ เพ้นท์ (ประเทศไทย) จำกัด (มหาชน) (เรียกว่า “บริษัทฯ ”) และบริษัทย่อย เคารพสิทธิความเป็นส่วนตัวของลูกค้า ผู้เข้าร่วมกิจกรรม (ต่อไปนี้เรียกว่า “ท่าน”) และเพื่อให้เกิดความมั่นใจว่าท่านได้รับความคุ้มครองข้อมูลส่วนบุคคล จึงได้จัดทำนโยบายความเป็นส่วนตัวฉบับนี้ขึ้น เพื่อแจ้งให้ท่านทราบถึงรายละเอียดที่เกี่ยวข้องกับการเก็บรวบรวม การใช้ และการเปิดเผย (รวมเรียกว่า “การประมวลผล”) ข้อมูลส่วนบุคคลของท่าน ทั้งออนไลน์และออฟไลน์ ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคล กำหนด ดังนี้
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>2. วัตถุประสงค์</b>
          <ul>
            <li>
              (1) เพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญาหรือเพื่อปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญากับบริษัทฯ เช่น เพื่อการขายสินค้า และ/หรือ บริการให้แก่ท่าน หรือปฏิบัติตามสัญญาใด ๆ ซึ่งท่านเป็นคู่สัญญา ซึ่งรวมถึง การจัดการ account ของท่าน จัดส่ง การดำเนินการเกี่ยวกับบัญชีและการเงิน การบริการหลังการขาย และการคืนสินค้า และดำเนินการใด ๆ เพื่อให้ท่านได้รับสินค้าและ/หรือ บริการ หรือตามที่ท่านได้ร้องขอ ตลอดจนกรณีที่ท่านได้เข้าร่วมกิจกรรมการตลาดต่างๆกับบริษัท เช่น การประกวด แข่งขัน ชิงรางวัล การสะสมคะแนนเพื่อแลก และ/หรือชิงรางวัล บริษัทจะใช้ข้อมูลของท่านเพื่อเป็นข้อมูลประกอบการร่วมกิจกรรม และการพิจารณา ตัดสิน และเป็นการยืนยันตัวตนในการแลก รับ ของรางวัล
            </li>
            <br />
            <li>
              บริษัทจะดูแลให้พนักงานที่ได้รับมอบหมายในเรื่องนี้โดยเฉพาะ มิให้ ใช้ หรือเปิดเผย แสดง หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลของท่าน นอกเหนือไปจากวัตถุประสงค์ ภายใต้หลักเกณฑ์ที่กฎหมายอนุญาตให้เปิดเผยได้ตามขอบเขตที่ท่านได้ให้ความยินยอม หรือขอบเขตที่เกี่ยวข้องในนโยบายฉบับนี้ ในกรณีที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลหรือนิติบุคคลภายนอก บุคคลหรือนิติบุคคลภายนอกนั้นจะต้องแจ้งวัตถุประสงค์ในการ ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้บริษัททราบก่อน และต้องไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคล นอกเหนือจากวัตถุประสงค์ที่ได้ให้ไว้แก่บริษัท
            </li>
            <br />
            <li>
              (2) เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือของบุคคลอื่น
              <!-- <ul>
                <li>
                  (1) เพื่อให้บริษัทฯ สามารถจัดการ พัฒนา และดำเนินการใด ๆ
                  เพื่อให้สามารถดำเนินธุรกิจได้ ซึ่งรวมถึง การบริหารจัดการและพัฒนาสินค้า และ/หรือ
                  บริการ (รวมถึงเว็บไซต์ และแอปพลิเคชัน) การตรวจสอบและป้องกันการฉ้อโกง
                  หรืออาชญากรรมอื่น ๆ การจัดการความสัมพันธ์ลูกค้าและผู้ที่อาจเป็นลูกค้า
                  การบำรุงรักษาและการใช้ระบบไอที
                </li>
                <li>
                  (2) เพื่อประโยชน์ทางด้านความปลอดภัย เช่น จัดให้มีมาตรการรักษาความปลอดภัย
                  ซึ่งรวมถึงข้อมูลส่วนบุคคลของท่านด้วย เช่น การเข้าสถานที่ของบริษัทฯ การ Log in
                  เข้าเว็บไซต์หรือแอปพลิเคชัน
                </li>
                <li>
                  (3) เพื่อดำเนินการทางการตลาด และวิเคราะห์ข้อมูล (Marketing and Data Analysis) เช่น
                  การแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทางอีเมล เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย
                  โทรศัพท์รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม เข้าสัมภาษณ์
                </li>
                <li>
                  (4) เพื่อให้บริษัท บันทึกภาพทั้งภาพนิ่งและภาพเคลื่อนไหว เสียง บทสัมภาษณ์ คำพูด
                  และยินยอมให้บริษัท เผยแพร่ข้อมูลส่วนบุคคล
                  ที่บริษัทได้บันทึกไว้ในการจัดกิจกรรมที่จัดขึ้นโดยบริษัทหรือที่บริษัทเป็นสปอนเซอร์
                  บนเว็บไซต์ สื่อ โซเชียลมีเดีย ภาพข่าวรวมถึงสื่อสาธารณะต่างๆ
                  โดยมีวัตถุประสงค์เพื่อประโยชน์ทางการค้า และผลิตภัณฑ์ของบริษัท
                  รวมทั้งกิจกรรมอื่นๆที่เป็นการสื่อสารสู่สังคม
                  และเพื่อประโยชน์ต่อการพัฒนากิจกรรมของโครงการดังกล่าวในระยะยาว
                </li>
                <li>(5) เพื่อการใช้สิทธิเรียกร้องทางกฎหมายที่เกี่ยวข้องกับบริษัทฯ และ/หรือ ท่าน</li>
              </ul> -->
            </li>
            <li>
              (3) เพื่อให้บริษัทฯ สามารถจัดการ พัฒนา และดำเนินการใด ๆ เพื่อให้สามารถดำเนินธุรกิจได้ ซึ่งรวมถึง การบริหารจัดการและพัฒนาสินค้า และ/หรือ บริการ (รวมถึงเว็บไซต์ และแอปพลิเคชัน) การตรวจสอบและป้องกันการฉ้อโกง หรืออาชญากรรมอื่น ๆ การจัดการความสัมพันธ์ลูกค้าและผู้ที่อาจเป็นลูกค้า การบำรุงรักษาและการใช้ระบบไอที
            </li>
            <li>
              (4) เพื่อประโยชน์ทางด้านความปลอดภัย เช่น จัดให้มีมาตรการรักษาความปลอดภัย ซึ่งรวมถึงข้อมูลส่วนบุคคลของท่านด้วย เช่น การเข้าสถานที่ของบริษัทฯ การ Log in เข้าเว็บไซต์หรือแอปพลิเคชัน
            </li>
            <li>
              (5) เพื่อดำเนินการทางการตลาด และวิเคราะห์ข้อมูล (Marketing and Data Analysis) เช่น การแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทางอีเมล เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย โทรศัพท์รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม เข้าสัมภาษณ์
            </li>
            <li>
              (6) เพื่อให้บริษัท บันทึกภาพทั้งภาพนิ่งและภาพเคลื่อนไหว เสียง บทสัมภาษณ์ คำพูด และยินยอมให้บริษัท เผยแพร่ข้อมูลส่วนบุคคล ที่บริษัทได้บันทึกไว้ในการจัดกิจกรรมที่จัดขึ้นโดยบริษัทหรือที่บริษัทเป็นสปอนเซอร์ บนเว็บไซต์ สื่อ โซเชียลมีเดีย ภาพข่าวรวมถึงสื่อสาธารณะต่างๆ โดยมีวัตถุประสงค์เพื่อประโยชน์ทางการค้า และผลิตภัณฑ์ของบริษัท รวมทั้งกิจกรรมอื่นๆที่เป็นการสื่อสารสู่สังคม และเพื่อประโยชน์ต่อการพัฒนากิจกรรมของโครงการดังกล่าวในระยะยาว
            </li>
            <li>(7) เพื่อการใช้สิทธิเรียกร้องทางกฎหมายที่เกี่ยวข้องกับบริษัทฯ และ/หรือ ท่าน</li>
            <li>
              (8) เพื่อป้องกันและระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของท่านหรือบุคคลอื่นเช่น การติดต่อในกรณีฉุกเฉิน การควบคุมและป้องกันโรคติดต่อ
            </li>
            <li>
              (9) เพื่อให้สามารถปฏิบัติตามกฎหมาย เช่น การปฏิบัติตามบทบัญญัติของกฎหมาย กฏระเบียบ และคำสั่งของผู้ที่มีอำนาจตามกฎหมาย
            </li>
            <li>
              (10) เพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้
            </li>
            <li>กรณีที่ท่านให้ความยินยอมบริษัทฯ จะประมวลผลข้อมูลส่วนบุคคล</li>
            <li>
              (11) เพื่อให้บริษัทในกลุ่มบริษัทฯ ที่ไม่ใช่ผู้ขายสินค้าและ/บริการท่านมาก่อนสามารถติดต่อสื่อสารกับท่าน เพื่อการแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทาง อีเมล เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย โทรศัพท์ รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม เข้าสัมภาษณ์ เพื่อใช้ในการวิเคราะห์พฤติกรรมการบริโภคของลูกค้า เพื่อนำไปพัฒนาโปรแกรม หรือโปรโมชั่นทางการตลาด
            </li>
            <li>
              (12) เพื่อให้บริษัทฯ ดำเนินการจัดกิจกรรมนอกจากที่กล่าวมาข้างต้น โดยอาจเก็บข้อมูลส่วนบุคคลของท่านเพิ่มเติม โดยจะแจ้งให้ทราบและขอความยินยอมจากท่านใหม่เป็นคราว ๆ ไป
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>3. ขอบเขต</b>
          <ul>
            <li>
              นโยบายฉบับนี้ให้มีผลบังคับใช้กับทุกการดำเนินงาน รวมถึงผู้บริหาร และพนักงานทุกคนของบริษัทฯ และบริษัทย่อย
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>4. ข้อมูลส่วนบุคคลที่เก็บรวบรวม</b>
          <ul>
            <li>
              ท่านที่ประสงค์จะใช้บริการบางประเภทที่ดำเนินการผ่านเว็บไซต์นี้ อาจต้องให้ข้อมูลส่วนบุคคลของท่านที่จำเป็นต่อการให้บริการดังกล่าว ตัวอย่างของการดำเนินการที่ผู้ใช้บริการต้องแจ้งข้อมูลส่วนบุคคล เช่น เมื่อผู้ใช้ประสงค์จะยื่นขอสมัครระบบ บริการ หรือธุรกรรมอื่นใดที่อาจมีขึ้นในอนาคต เป็นต้น บริษัทอาจเก็บรวบรวมข้อมูลใดๆ ของท่านซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม ที่ท่านได้ให้ไว้กับบริษัท โดยการลงทะเบียนเข้าร่วมกิจกรรมต่างๆ ของบริษัท คุกกี้ ข้อมูลการทำรายการ และประสบการณ์การใช้งานผ่านหน้าเว็บไซต์ หรือที่ท่านได้อนุญาตให้บริษัทได้เข้าถึง (“ข้อมูลส่วนบุคคล”) โดยรวมถึงข้อมูลดังต่อไปนี้ด้วย
            </li>
            <li>
              (1) เมื่อท่านจะซื้อสินค้า และ/หรือ บริการ รวมทั้งการเข้าเป็นสมาชิกเว็บไซต์ แอปพลิเคชัน หรือบัตรสิทธิประโยชน์ต่าง ๆ เช่น เว็บแอพลิเคชั่น Line OA TOA Paint บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคล ดังนี้
              <!-- <ul>
                <li>
                  (1) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด เพศ สถานภาพ ที่อยู่
                  ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชน Facebook ID Line บัตรประชาชน Passport
                  รูปถ่ายบ้าน แบบบ้าน ประวัติการสนทนา ประวัติสุขภาพ
                  (เฉพาะกรณีที่ท่านใช้บริการหรือผลิตภัณฑ์ที่อาจมีความเกี่ยวพันกับสุขภาพ)
                </li>
                <li>
                  (2) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่ ช่องทางติดต่อในโซเชียลมีเดีย
                  สถานที่ทำงาน
                </li>
                <li>(3) ข้อมูลเกี่ยวกับงานของท่าน เช่น อาชีพ ตำแหน่ง ประสบการณ์การทำงาน</li>
                <li>
                  (4) ข้อมูลเกี่ยวกับการซื้อสินค้า และ/หรือ บริการ เช่น ประเภทสินค้าที่ท่านซื้อ
                  ประวัติการซื้อสินค้า ประวัติการเคลมสินค้า ข้อร้องเรียน ใบเสร็จรับเงิน
                  หรือหลักฐานการชำระราคาสินค้าที่เกี่ยวข้อง เป็นต้น
                </li>
                <li>
                  (5) ข้อมูลที่ท่านได้ให้ไว้แก่บริษัท บริษัทในเครือ เมื่อท่านติดต่อ และ/หรือ
                  ร่วมกิจกรรมต่าง ๆ กับบริษัท บริษัทในเครือ เมื่อบริษัท บริษัทในเครือบริษัทฯ
                  เข้าพบท่าน ดูแลให้บริการหลังการขาย การทำวิจัย และการสัมภาษณ์
                </li>
              </ul> -->
            </li>
            <li>
              (2) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด เพศ สถานภาพ ที่อยู่ ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชน Facebook ID Line บัตรประชาชน Passport รูปถ่ายบ้าน แบบบ้าน ประวัติการสนทนา ประวัติสุขภาพ (เฉพาะกรณีที่ท่านใช้บริการหรือผลิตภัณฑ์ที่อาจมีความเกี่ยวพันกับสุขภาพ)
              <!-- <ul>
                <li>
                  (1) ข้อมูลเกี่ยวกับการสมัครสมาชิก (Registration) เช่น ชื่อ นามสกุล อีเมล
                  เบอร์มือถือ รหัสผ่าน
                </li>
                <li>
                  (2) ข้อมูลเกี่ยวกับอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้ เช่น IP Address หรือ other
                  device identifier
                </li>
                <li>
                  (3) ชนิดและเวอร์ชันของบราวเซอร์ที่ท่านใช้ รวมถึงชนิดและเวอร์ชันของ plug-in
                  ของบราวเซอร์
                </li>
                <li>(4) การตั้งค่าเขตเวลา (Time zone)</li>
              </ul> -->
            </li>
            <li>
              (3) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่ ช่องทางติดต่อในโซเชียลมีเดีย สถานที่ทำงาน
              <!-- <ul>
                <li>
                  (1) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด รูปถ่าย หมายเลขบัตรประชาชน
                  หมายเลขใบขับขี่ หมายเลขหนังสือเดินทาง ข้อมูลสุขภาพ
                </li>
                <li>(2) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่</li>
                <li>(3) ข้อมูลเกี่ยวกับการร่วมกิจกรรม เช่น ประวัติการร่วมกิจกรรมในครั้งก่อน ๆ</li>
              </ul> -->
            </li>
            <li>
              (4) ข้อมูลเกี่ยวกับงานของท่าน เช่น อาชีพ ตำแหน่ง ประสบการณ์การทำงาน
              <!-- <ul>
                <li>
                  (1) เมื่อบริษัทฯ
                  จำเป็นต้องใช้ข้อมูลดังกล่าวเพื่อประโยชน์ในการรักษาความปลอดภัยของบริษัทฯ เช่น
                  ข้อมูลชีวภาพ (facial recognition ลายนิ้วมือ) เพื่อใช้ในการระบุตัวตน
                </li>
                <li>
                  (2) ในบางกรณี บริษัทฯ อาจเก็บข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
                  แม้ว่าสินค้าหรือบริการนั้นไม่ได้เกี่ยวข้องกับข้อมูลชนิดพิเศษโดยตรง เช่น บริษัทฯ
                  จำเป็นต้องใช้บัตรประชาชนของท่าน ซึ่งมีข้อมูลศาสนา เพื่อใช้ในการยืนยันตัวตนของท่าน
                  หรือดำเนินการทางภาษี
                </li>
                <li>(3) ข้อมูลสุขภาพ เช่น ข้อมูลการแพ้อาหาร เพื่อใช้ในการดำเนินกิจกรรมต่าง ๆ</li>
              </ul> -->
            </li>
            <li>
              (5) ข้อมูลเกี่ยวกับการซื้อสินค้า และ/หรือ บริการ เช่น ประเภทสินค้าที่ท่านซื้อ ประวัติการซื้อสินค้า ประวัติการเคลมสินค้า ข้อร้องเรียน ใบเสร็จรับเงิน หรือหลักฐานการชำระราคาสินค้าที่เกี่ยวข้อง เป็นต้น
            </li>
            <li>
              (6) ข้อมูลที่ท่านได้ให้ไว้แก่บริษัท บริษัทในเครือ เมื่อท่านติดต่อ และ/หรือ ร่วมกิจกรรมต่าง ๆ กับบริษัท บริษัทในเครือ เมื่อบริษัท บริษัทในเครือบริษัทฯ เข้าพบท่าน ดูแลให้บริการหลังการขาย การทำวิจัย และการสัมภาษณ์
            </li>
            <li>
              (7) เมื่อท่านเข้าชม และ/หรือ ซื้อสินค้าออนไลน์ หรือเข้าชมเว็บไซต์หรือแอปพลิเคชัน บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคล ดังนี้
              <ul>
                <li>
                  7.1 ข้อมูลเกี่ยวกับการสมัครสมาชิก (Registration) เช่น ชื่อ นามสกุล อีเมล เบอร์มือถือ รหัสผ่าน
                </li>
                <li>
                  7.2 ข้อมูลเกี่ยวกับอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้ เช่น IP Address หรือ other device identifier
                </li>
                <li>
                  7.3 ชนิดและเวอร์ชันของบราวเซอร์ที่ท่านใช้ รวมถึงชนิดและเวอร์ชันของ plug-in ของบราวเซอร์
                </li>
                <li>7.4 การตั้งค่าเขตเวลา (Time zone)</li>
              </ul>
            </li>
            <li>
              (8) เมื่อท่านติดต่อบริษัทฯ หรือร่วมกิจกรรมใด ๆ กับบริษัทฯ บริษัทฯ จะเก็บข้อมูลส่วนบุคคล เช่น ติดต่อ Contact Center การทำ Customer Satisfaction กิจกรรมสิทธิประโยชน์ต่าง ๆ
              <ul>
                <li>
                  8.1 ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด รูปถ่าย หมายเลขบัตรประชาชน หมายเลขใบขับขี่ หมายเลขหนังสือเดินทาง ข้อมูลสุขภาพ
                </li>
                <li>8.2 ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่</li>
                <li>8.3 ข้อมูลเกี่ยวกับการร่วมกิจกรรม เช่น ประวัติการร่วมกิจกรรมในครั้งก่อน ๆ</li>
              </ul>
            </li>
            <li>
              (9) บริษัทฯ อาจมีความจำเป็นต้องเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด เพื่อใช้งานตามวัตถุประสงค์ที่บริษัทฯ แจ้งไว้ในนโยบายความเป็นส่วนตัวนี้ เช่น
            </li>
            <li>
              (10) เมื่อบริษัทฯ จำเป็นต้องใช้ข้อมูลดังกล่าวเพื่อประโยชน์ในการรักษาความปลอดภัยของบริษัทฯ เช่น ข้อมูลชีวภาพ (facial recognition ลายนิ้วมือ) เพื่อใช้ในการระบุตัวตน
            </li>
            <li>
              (11) ในบางกรณี บริษัทฯ อาจเก็บข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน แม้ว่าสินค้าหรือบริการนั้นไม่ได้เกี่ยวข้องกับข้อมูลชนิดพิเศษโดยตรง เช่น บริษัทฯ จำเป็นต้องใช้บัตรประชาชนของท่าน ซึ่งมีข้อมูลศาสนา เพื่อใช้ในการยืนยันตัวตนของท่าน หรือดำเนินการทางภาษี
            </li>
            <li>(12) ข้อมูลสุขภาพ เช่น ข้อมูลการแพ้อาหาร เพื่อใช้ในการดำเนินกิจกรรมต่าง ๆ</li>
            <li>
              (13) ในกรณีที่จำเป็น บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านโดยได้รับความยินยอมโดยชัดแจ้งจากท่านหรือเพื่อวัตถุประสงค์อื่นตามที่กฎหมายกำหนดไว้เท่านั้น ทั้งนี้ บริษัทฯ จะใช้ความพยายามอย่างดีที่สุดในการจัดให้มีมาตรการรักษาความปลอดภัยที่เพียงพอเพื่อปกป้องคุ้มครองข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>5. ข้อมูลส่วนบุคคลที่มีความอ่อนไหว</b>
          <ul>
            <li>
              (1) บริษัทฯ อาจมีความจำเป็นต้องเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านเพื่อใช้งานตามวัตถุประสงค์ที่บริษัทฯ แจ้งไว้ในนโยบายความเป็นส่วนตัวนี้
            </li>
            <li>
              (2) บริษัทฯ อาจต้องประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน ในกรณีดังต่อไปนี้
              <!-- <ul>
                <li>
                  (1) ข้อมูลชีวภาพ (biometric data) เช่น ข้อมูลจำลองลายนิ้วมือ ข้อมูลภาพจำลองใบหน้า
                  เพื่อใช้ในการระบุและยืนยันตัวตนของท่าน การป้องกันอาชญากรรม
                  และการรักษาประโยชน์โดยชอบด้วยกฎหมายของ บริษัทฯ หรือของบุคคลอื่น
                </li>
                <li>
                  (2) ความเชื่อในลัทธิศาสนา ปรัชญา เชื้อชาติ สัญชาติ ความพิการ ข้อมูลพันธุกรรม
                  ข้อมูลชีวภาพ
                </li>
                <li>
                  (3) ข้อมูลส่วนบุคคลที่มีความอ่อนไหวอื่น ๆ ตามวัตถุประสงค์อันชอบด้วยกฎหมาย เช่น
                  เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                  หรือสุขภาพของบุคคลในกรณีที่ท่านไม่สามารถให้ความยินยอมได้
                  เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของท่าน
                  เพื่อใช้สิทธิเรียกร้องตามกฎหมาย เพื่อบรรลุวัตถุประสงค์เกี่ยวกับการคุ้มครองแรงงาน
                  การประกันสังคม และสวัสดิการของพนักงาน
                </li>
              </ul> -->
            </li>
            <li>
              (3) ข้อมูลชีวภาพ (biometric data) เช่น ข้อมูลจำลองลายนิ้วมือ ข้อมูลภาพจำลองใบหน้า เพื่อใช้ในการระบุและยืนยันตัวตนของท่าน การป้องกันอาชญากรรม และการรักษาประโยชน์โดยชอบด้วยกฎหมายของ บริษัทฯ หรือของบุคคลอื่น
            </li>
            <li>
              (4) ความเชื่อในลัทธิศาสนา ปรัชญา เชื้อชาติ สัญชาติ ความพิการ ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
            </li>
            <li>
              (5) ข้อมูลส่วนบุคคลที่มีความอ่อนไหวอื่น ๆ ตามวัตถุประสงค์อันชอบด้วยกฎหมาย เช่น เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคลในกรณีที่ท่านไม่สามารถให้ความยินยอมได้ เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของท่าน เพื่อใช้สิทธิเรียกร้องตามกฎหมาย เพื่อบรรลุวัตถุประสงค์เกี่ยวกับการคุ้มครองแรงงาน การประกันสังคม และสวัสดิการของพนักงาน
            </li>
            <li>
              (6) ในกรณีที่จำเป็น บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านโดยได้รับความยินยอมโดยชัดแจ้งจากท่านหรือเพื่อวัตถุประสงค์อื่นตามที่กฎหมายกำหนดไว้เท่านั้น ทั้งนี้ บริษัทฯ จะใช้ความพยายามอย่างดีที่สุดในการจัดให้มีมาตรการรักษาความปลอดภัยที่เพียงพอเพื่อปกป้องคุ้มครองข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b> 6. การเก็บรวบรวม และการได้รับข้อมูลส่วนบุคคลของท่าน</b>
          <ul>
            <li>
              (1) บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยตรง และ/หรือจากแหล่งข้อมูลอื่นที่ไม่ใช่จากเจ้าของข้อมูลโดยตรง เช่น ข้อมูลที่เผยสู่สารธารณะ ผู้ให้บริการภายนอก นายทะเบียนหลักทรัพย์ เป็นต้น ทั้งนี้ ข้อมูลที่ได้รับมาจากแหล่งอื่นจะได้รับการตรวจสอบหรือรับรองแล้วว่าสามารถนำมาใช้ได้ตามวัตถุประสงค์ของนโยบายฉบับนี้
            </li>
            <li>
              (2) บริษัทอาจเข้าถึงข้อมูลเกี่ยวกับความสนใจ และ/หรือความชอบของท่าน และ/หรือข้อมูลการเข้าใช้งานเว็บไซต์ต่างๆ ของท่านด้วยการใช้คุกกี้ในเว็บไซด์ และแอปพลิเคชันของบริษัท โดยคุกกี้ที่อยู่ในเว็บไซด์ และแอปพลิเคชันของบริษัทจะเก็บข้อมูลของท่านไว้ และบริษัทจะสามารถเข้าถึงข้อมูลดังกล่าวได้ ทั้งนี้ เพื่อให้บริษัทสามารถนำเสนอบริการที่ตรงกับความต้องการของท่านให้ได้มากที่สุด นอกจากนี้ เมื่อท่านดาวน์โหลดและติดตั้งแอปพลิเคชันของบริษัทในบริษัทไว้ในมือถือหรืออุปกรณ์อิเล็กทรอนิกส์อื่นใดของท่าน บริษัทอาจเข้าถึงข้อมูลต่างๆ ที่ท่านมีไว้เพื่อใช้ในการติดต่อระหว่างท่านกับบริษัท ไม่ว่าจะเป็นทางโทรศัพท์ อีเมล แอปพลิเคชันของบริษัท
            </li>
          </ul>
        </li>
        <br />
        <br />
        <li>
          <b>กิจกรรม Mega Paint สะสมยอดซื้อสินค้า ทีโอเอ (ทุกกลุ่มสินค้าของTOA)</b>
          <ul>
            <li>
              วิธีการลงทะเบียนเข้าร่วมกิจกรรม : เพิ่มเพื่อนและลงทะเบียนผ่านทาง Application Line Official Account : @Mega Paint สมัครสมาชิก ระบุ ชื่อ - นามสกุล, เบอร์โทรศัพท์, ถ่ายรูปบัตรประชาชน (ด้านหน้า) ให้ครบถ้วน ผู้ขอใช้บริการสามารถลงทะเบียนเข้าใช้งาน ได้โดยจะต้องทำการให้รายละเอียดข้อมูลตามที่บริษัทกำหนด รวมทั้งได้รับอนุมัติจากบริษัทฯให้เป็นสมาชิก โดยผู้ใช้บริการต้องผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขในการใช้บริการ
            </li>
            <li>สมาชิกรับรองว่าบรรดาข้อมูลที่ได้ให้ไว้แก่บริษัทในการลงทะเบียนนี้ถูกต้องครบถ้วน โดยเฉพาะอย่างยิ่งรับรองว่าเบอร์โทรศัพท์มือถือยังใช้งานอยู่ในขณะที่ได้ให้ข้อมูลดังกล่าว ซื้อสินค้า TOA ทุกกลุ่มสินค้า เฉพาะร้าน Mega Paint and Home</li>
            <li>ใบเสร็จ ที่เข้าร่วมกิจกรรม คือ ใบเสร็จรับเงิน / ใบกำกับภาษี / ใบกำกับภาษีอย่างย่อ / บิลเงินสดที่มีชื่อ ที่อยู่ เลขประจำตัวผู้เสียภาษี ของร้านค้า, ชื่อลูกค้า, เลขที่บิล, วันที่ซื้อขาย, มีลายเซ็นผู้รับเงิน ตรงกับ หนังสือบริคณห์สนธิ พร้อม ตราประทับร้านค้า โดยรายละเอียดในใบเสร็จต้องมี ที่มีชื่อและที่อยู่ร้านค้า, เลขประจำตัวผู้เสียภาษีของร้านค้า, เลขที่ใบเสร็จ, มูลค่าและสินค้าที่ซื้อ และวันที่ซื้อได้อย่างชัดเจน</li>
            <li>ถ่ายรูปใบเสร็จ และ อัปโหลดใบเสร็จผ่านทาง Appilcation Line Official Account : @Mega Paint ในกรณี ใบเสร็จตรงตามเงื่อนไขข้างต้น จะได้รับแต้มสะสม เพื่อแลกของรางวัล ผ่านทาง Application Line Official Account : @Mega Paint</li>
            <li>ทางบริษัทจะอนุมัติใบเสร็จที่เข้าร่วมกิจกรรมและอัปเดทคะแนน ภายใน 2 - 3 วันทำการ หลังจากที่รับข้อมูลที่ถูกต้องครบถ้วน</li>
            <li>บริษัทขอสงวนสิทธิในการยกเลิกการเป็นสมาชิก หรือเปลี่ยนแปลงเงื่อนไขสิทธิประโยชน์โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
            <li>กิจกรรมนี้เฉพาะ End User เท่านั้น ร้านค้าที่เป็นตัวแทนจำหน่ายสี ทีโอเอ ไม่สามารถเข้าร่วมรายการนี้ได้ หากตรวจพบว่ามีการทุจริต ขอระงับการเป็นสมาชิก และไม่จ่ายผลประโยชน์ทั้งสิ้น โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
            <li>การคำนวณแต้ม :</li>
            <li>ทุกๆ การซื้อสินค้า ทีโอเอ 1 บาท เท่ากับ 1 แต้ม เท่านั้น สำหรับยอดเงินที่เป็นเศษสตางค์ “ไม่” สามารถสะสมแต้มได้</li>
            <li>ของรางวัล รายละเอียดดังนี้ :</li>
            <li>หม้อไฟฟ้า KASHIWA รุ่น KW-352</li>
            <li>พัดลม MITSUBISHI 16 นิ้ว รุ่น D16A-GB CY</li>
            <li>เครื่องดูดฝุ่น SHARP รุ่น EC-SC42B-B</li>
            <li>รายการสินค้าเข้าร่วม คือ สินค้า ทีโอเอ ทุกกลุ่มสินค้าที่จัดจำหน่ายในร้าน Mega Paint and Home</li>
            <li>ระยะเวลากิจกรรม :</li>
            <li>สามารถอัปโหลดใบเสร็จ สะสมได้ตั้งแต่วันที่ 1 มิถุนายน 2567 เวลา 0.01 น. – 30 มิถุนายน 2567 เวลา 23.59 น. โดยบนใบเสร็จต้องระบุวันที่ ที่ทำการซื้อสินค้าเข้าร่วมรายการ เริ่มตั้งแต่วันที่ 1 มิถุนายน 2567 เท่านั้น ขอสงวนสิทธิ์ในการปฏิเสธใบเสร็จที่ทำการซื้อสินค้าก่อนวันที่ 1 มิถุนายน 2567</li>
            <li>สามารถแลกรางวัลจากคะแนนสะสมได้ตั้งแต่วันที่ 1 มิถุนายน 2567 เวลา 0.01 น. - 15 กรกฎาคม 2567 เวลา 23.59 น.</li>
            <li>หากบริษัทฯ ตรวจพบว่ามีเจตนาทุจริต ยกเลิก หรือขอคืนสินค้าภายหลัง ขอสงวนสิทธิ์ในการระงับการอนุมัติใบเสร็จทันที</li>
            <li>ร้านค้าที่เข้าร่วมรายการ</li>
            <li>ร้านค้าตัวแทนจำหน่าย TOA ที่เข้าร่วมรายการ เท่านั้น</li>
            <li>เงื่อนไขการแลกรับของรางวัล :</li>
            <li>ผู้ร่วมกิจกรรมต้องรับทราบและยินยอมปฏิบัติตามกติกา เงื่อนไขและข้อตกลงการเข้าร่วมกิจกรรมฉบับนี้โดยไม่มีข้อโต้แย้งใด ๆ รวมถึงข้อกำหนดในการใช้งานของทางแอปพลิเคชั่นไลน์ พนักงานและครอบครัวของพนักงาน บริษัท ทีโอเอ เพ้นท์ (ประเทศไทย) จำกัด (มหาชน) บริษัทในเครือ บริษัทตัวแทนโฆษณา คณะกรรมการดำเนินรายการ และบริษัทที่มีส่วนเกี่ยวข้องในการดำเนิน การจัดรายการในครั้งนี้ไม่มีสิทธิ์เข้าร่วมรายการ</li>
            <li>แต้มสะสมและของรางวัลที่ได้รับไม่สามารถ แลก ทอน เปลี่ยนเป็นเงินสด หรือของรางวัลอื่นได้ และไม่สามารถโอนสิทธิ์ให้กับบุคคลภายนอกหรือนิติบุคคลอื่นได้ ไม่ว่ากรณีใด รวมทั้งไม่มีการจ่ายเงินเป็นส่วนประกอบเเต่อย่างใด</li>
            <li>บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงรายละเอียดต่างๆ เกี่ยวกับของรางวัล โดยไม่ต้องแจ้งให้ทราบล่วงหน้า ทั้งนี้ของรางวัลใหม่นั้นจะมีมูลค่าเทียบเท่าของรางวัลเดิม</li>
            <li>บริษัทฯ มิได้มีส่วนเกี่ยวข้องในการจัดจำหน่ายของรางวัลที่ระบุในรายการนี้ บริษัทฯ จะไม่รับประกันและไม่รับผิดชอบต่อคุณภาพ หรือบริการที่อาจเกิดขึ้นกับรางวัลดังกล่าว หากมีปัญหาเกี่ยวกับคุณภาพ หรือบริการ ผู้โชคดีจะต้องติดต่อที่บริษัทฯ ที่จำหน่ายหรือให้บริการโดยตรง</li>
            <li>บริษัทฯ จะไม่รับผิดชอบต่อระบบอินเตอร์เน็ตที่ล่าช้า ไม่สมบูรณ์ หรือเกิดจากการฉ้อโกงหรือการส่งข้อมูลที่ไม่ถูกต้อง ที่เกิดจากผู้เข้าร่วมรายการหรือระบบอินเตอร์เน็ตที่ล่าช้า ไม่ว่าจะเนื่องมาจากสาเหตุของความผิดพลาด การเพิกเฉย การแก้ไขปรับเปลี่ยน การให้สินบน การลบทิ้ง ขโมย การทำลายข้อมูลโดยไม่ได้รับอนุญาตหรือการลักลอบใช้ข้อมูล ความเสียหายของข้อมูล เครือข่ายล้มเหลว หรือความผิดพลาดของซอฟต์แวร์หรือฮาร์ดแวร์ หรือไม่ว่าเหตุใด</li>
            <li>ผู้เข้าร่วมกิจกรรม ต้องสะสมแต้ม แลกของรางวัลภายในวันที่ 15 กรกฎาคม 2567 เวลา 23.59 น. หากเลยระยะเวลาวันดังกล่าว ถือว่าสะสิทธิ์ในการแลกของรางวัล</li>
            <li>ของรางวัล บริษัทฯ จะถูกส่งไปให้ที่ สาขา Mega Paint and Home ที่ใกล้ท่าน ภายในระยะเวลา 7-14 วันทำการ นับจากวันที่ผู้เข้าร่วมกิจกรรมกดแลกของรางวัล ทั้งนี้ เนื่องด้วยสถานการณ์การแพร่ระบาดของโควิท-19 อาจเกิดความล่าช้าจากการขนส่ง</li>
            <li>แต้มที่ใช้แลกแล้ว จะไม่สามารถนำกลับมาใช้ได้อีก เช่น แต้มที่แลกรับของรางวัลแล้ว จะไม่สามารถนำมาแลกรางวัลได้อีก และไม่สามารถขอคืนได้ไม่ว่ากรณีใดๆ ทั้งสิ้น โดยหากมีคะแนน</li>
            <li>สะสมเหลือหลังพ้นช่วงระยะเวลาร่วมรายการนี้แล้ว จะถือว่าแต้มนั้นหมดอายุทันที ไม่สามารถนำคะแนนไปรวมกับ กิจกรรมอื่นที่ทางบริษัทจัดได้อีก</li>
            <li>เงื่อนไขวันหมดอายุแต้ม</li>
            <li>แต้มที่สะสมจะหมดอายุใน วันที่ 15 กรกฎาคม 2567 เวลา 23.59 น.</li>
            <li>การปรับเปลี่ยนข้อมูลส่วนตัวของสมาชิก</li>
            <li>สมาชิกสามารถทำการปรับเปลี่ยนข้อมูล ผ่านทาง Application Line Official Account : @TOA-CLUB</li>
            <li>สมาชิกรับรองว่า หากมีการเปลี่ยนแปลงข้อมูลส่วนตัวของสมาชิก สมาชิกจะปรับปรุงข้อมูลส่วนตัวของสมาชิกให้ทันสมัยอยู่ตลอดเวลา</li>
            <li>ติดตามรายละเอียดและสอบถามข้อมูลเพิ่มเติมได้ที่ Application Line Official Account :</li>
            <li>@TOA-CLUB วันจันทร์ - ศุกร์ เวลา 09.00 น. - 17.00 น. ยกเว้นวันหยุดนักขัตฤกษ์</li>
          </ul>
        </li>
        <br />
        <br />
      </ul>
      <div class="text-center">
        <div>แบบฟอร์มการขอใช้สิทธิข้อมูลส่วนบุคคลสำหรับลูกค้า สามารถ Download ได้จาก</div>
        <div>
          Link:&nbsp;<span @click="download" class="red--text"
            ><u>
              <a style="color: inherit; text-decoration: none" href="https://toaecoupon.zwiz.ai/customer_consent_form.pdf" download="customerConsentForm">ดาวน์โหลด</a>
            </u></span
          >
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.topic {
  padding-left: 0px !important;
}
ul li {
  list-style: none;
}
</style>
