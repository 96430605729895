<template>
  <MainLayout>
    <div v-if="!loading">
      <div
        class="main-color pa-4 text-center white--text"
        style="font-size: 22px"
      >
        รางวัล
      </div>
      <div
        style="cursor: pointer"
        class="pa-4"
        @click="$router.push({ name: 'reward', query: { type: 'ecoupon' } })"
      >
        <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span
          class="primary--text"
          >กลับ</span
        >
      </div>
      <template v-if="ecoupon && ecoupon.ecoupon_status">
        <v-img
          :src="ecoupon.photo"
          aspect-ratio="1.89"
          :style="{ filter: IsUsed ? 'grayscale(100%)' : '' }"
          contain
        />
        <div
          v-if="IsUsed"
          style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
          <div
            style="
              border: 3px solid red;
              padding: 20px;
              color: red;
              width: 200px;
              height: max-content;
              text-align: center;
              font-size: 22px;
            "
          >
            <b>ใช้แล้ว</b>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-space-between">
          <div>
            <img src="@/assets/lotus_logo.png" width="200px" height="60px" />
          </div>
          <div>
            <img src="@/assets/logo.png" width="70px" height="60px" />
          </div>
        </div>
        <div
          style="height: 220px; padding: 10px; position: relative"
          :class="{ avaiableCard: !IsUsed, usedCard: IsUsed }"
        >
          <div class="pa-2">
            <div class="white--text ml-7 mt-4">
              <div style="font-size: 30px">บัตรกำนัล โลตัส</div>
              <div>มูลค่า</div>
              <div>
                <span style="font-size: 70px">{{
                  formattedNumber(amount)
                }}</span
                >&nbsp;&nbsp;<span>บาท</span>
              </div>
            </div>
          </div>
          <div
            v-if="IsUsed"
            style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
          >
            <div
              style="
                border: 3px solid red;
                padding: 20px;
                color: red;
                width: 200px;
                height: max-content;
                text-align: center;
                font-size: 22px;
              "
            >
              <b>ใช้แล้ว</b>
            </div>
          </div>
        </div>
      </template>
      <br />
      <div class="pl-5 mb-3">
        <u class="secondary--text" @click="openTerm">ดูเงื่อนไขการใช้งาน</u>
      </div>
      <div>
        <div class="d-flex justify-center">
          <VueBarcode :value="barcode"> ไม่สามารถสร้างบาร์โค้ดได้ </VueBarcode>
        </div>
        <div style="color: #626262" class="pl-5">
          หมดอายุ วันที่ 31 มีนาคม 2567 เวลา 23:59 น.
        </div>
      </div>
      <div class="ma-4">
        <v-btn
          v-if="!IsUsed"
          color="main-color"
          class="white--text mt-10"
          block
          @click="showDialog = true"
          rounded
          large
          >ใช้คูปอง</v-btn
        >
      </div>
      <v-dialog v-model="showDialog" width="450px" height="450px">
        <v-card>
          <div
            style="display: flex; justify-content: center; text-align: center"
          >
            <div style="width: 350px; margin: 30px">
              <div class="mb-4">
                <h3>
                  <span class="primary--text"
                    >ท่านต้องการยืนยัน<br />การใช้งานคูปองหรือไม่</span
                  >
                </h3>
              </div>
              <v-btn
                color="main-color"
                class="white--text"
                block
                @click="useCoupon"
                rounded
                large
                >ตกลง</v-btn
              >
              <v-btn
                color="gray"
                block
                @click="showDialog = false"
                rounded
                class="mt-3"
                large
                >ยกเลิก</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </MainLayout>
</template>

<script>
import api from "@/api";
import ecouponApi from "@/api/ecoupon";
import VueBarcode from "vue-barcode";
export default {
  components: {
    VueBarcode,
  },
  data() {
    return {
      barcode: null,
      amount: null,
      IsUsed: false,
      showDialog: false,
      loading: true,
      couponData: null,
      ecoupon_id: null,
      ecoupon: null,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.barcode = this.$route.query.barcode;
    this.amount = this.$route.query.amount;
    if (this.barcode && this.amount) {
      this.$store.commit("setLoading", true);
      await this.checkValidEcoupon();
      this.loading = false;
      this.$store.commit("setLoading", false);
    } else {
      this.$router.push({ name: "none" });
    }
  },
  methods: {
    async checkValidEcoupon() {
      let data = {
        user_id: this.userId,
        page_id: this.pageId,
        barcode: this.barcode,
      };

      try {
        let response = await api.validateEcoupon(data);
        if (response.data && response.data.data) {
          this.couponData = response.data.data;
          if (this.couponData.is_valid) {
            this.amount = this.couponData.detail.amount;
            this.IsUsed = this.couponData.detail.is_used;

            this.ecoupon_id =
              this.couponData.detail.ecoupon &&
              this.couponData.detail.ecoupon.length > 0
                ? this.couponData.detail.ecoupon[0].id
                : null;

            this.ecoupon = this.couponData.detail.productdetails
              ? this.couponData.detail.productdetails.find(
                  (el) => el.id == this.ecoupon_id
                )
              : undefined;
          } else {
            this.$router.push({ name: "none" });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async useCoupon() {
      this.showDialog = false;
      try {
        this.$store.commit("setLoading", true);
        await ecouponApi.useEcoupon({
          user_id: this.userId,
          page_id: this.pageId,
          barcode: this.barcode,
        });
        console.log(2);
        this.IsUsed = true;
      } catch (error) {
        this.$swal.fire({
          type: "error",
          text: `ไม่สามารถใช้คูปองได้`,
        });
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    openTerm() {
      this.$router.push({
        name: "rewardTerm",
        query: { barcode: this.barcode, amount: this.amount },
      });
    },
  },
};
</script>

<style scoped>
.avaiableCard {
  background: #17a26b;
}
.usedCard {
  background: #b7b7b7;
}
</style>

<style>
.barcode .vue-barcode-element {
  width: 100%;
}
</style>
