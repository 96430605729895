var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{attrs:{"showBottomNav":""}},[_c('div',{staticClass:"main-color pa-4 text-center white--text",staticStyle:{"font-size":"22px"}},[_vm._v(" แก้ไขข้อมูลส่วนตัว ")]),_c('div',{staticClass:"pa-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'profile', params: { getProfile: true } })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-left")]),_vm._v(" "),_c('span',{staticClass:"primary--text"},[_vm._v("กลับ")])],1),_c('div',{staticClass:"pa-7"},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"rules":{
          required: true,
        },"name":"ชื่อจริง"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('custom-input',{attrs:{"prepend-inner-icon":"mdi-account","placeholder":"ชื่อจริง","rounded":"","solo":"","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
          required: true,
        },"name":"นามสกุล"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('custom-input',{attrs:{"prepend-inner-icon":"mdi-account","placeholder":"นามสกุล","rounded":"","solo":"","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
          required: true,
          thai_phonenumber: true,
          digits: 10,
          regex: /^[0][3-9][0-9]{4}[0-9]{4}$/,
        },"name":"เบอร์โทรศัพท์"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('custom-input',{attrs:{"placeholder":"เบอร์โทร","type":"tel","maxlength":"10","dense":"","rounded":"","solo":"","prepend-inner-icon":"mdi-phone-in-talk-outline","numberOnly":"","outlined":"","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
          required: true,
        },"name":"อาชีพ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('custom-input',{attrs:{"prepend-inner-icon":"mdi-account","placeholder":"อาชีพ","rounded":"","solo":"","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}})]}}])})],1),_c('v-btn',{staticClass:"white--text mt-4",attrs:{"color":"main-color","block":"","rounded":"","large":""},on:{"click":_vm.onSubmit}},[_vm._v("บันทึก")])],1),_c('v-dialog',{attrs:{"width":"450px","height":"450px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('div',{staticStyle:{"display":"flex","justify-content":"center","text-align":"center"}},[_c('div',{staticStyle:{"width":"350px","margin":"30px"}},[_c('v-icon',{staticClass:"mb-4",staticStyle:{"font-size":"95px"},attrs:{"color":"primary","large":""}},[_vm._v("mdi-check-circle")]),_c('h3',{staticClass:"mb-4"},[_vm._v("แก้ไขข้อมูลสำเร็จ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"main-color","block":"","rounded":"","large":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("ปิด")])],1)])])],1),_c('v-dialog',{attrs:{"width":"450px","height":"450px"},model:{value:(_vm.showDuplicateDialog),callback:function ($$v) {_vm.showDuplicateDialog=$$v},expression:"showDuplicateDialog"}},[_c('v-card',[_c('div',{staticStyle:{"display":"flex","justify-content":"center","text-align":"center"}},[_c('div',{staticStyle:{"width":"350px","margin":"30px"}},[_c('v-icon',{staticClass:"mb-4",staticStyle:{"font-size":"95px"},attrs:{"color":"primary","large":""}},[_vm._v("mdi-alert-circle")]),_c('h3',{staticClass:"mb-4"},[_vm._v("พบข้อมูลซ้ำ")]),_c('v-btn',{staticClass:"mb-2 white--text",attrs:{"color":"main-color","block":"","rounded":"","large":""},on:{"click":function($event){_vm.showDuplicateDialog = false}}},[_vm._v("ปิด")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }