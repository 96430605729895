<template>
  <MainLayout>
    <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
      กรอกที่อยู่รับของรางวัล
    </div>
    <div class="pa-4" v-if="type != 'updateinfo'" style="cursor: pointer;" @click="$router.push({ name: 'productReward' })"><v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span></div>
    <div class="pa-5">
      <ValidationObserver ref="observer">
        <template v-if="(type == 'updateinfo' && !has_ecoupon) || type != 'updateinfo'">
          <div>ชื่อ</div>
          <ValidationProvider rules="required" name="ชื่อ" v-slot="{ errors }">
            <custom-input type="text" dense solo v-model="input.first_name" outlined :error-messages="errors"></custom-input>
          </ValidationProvider>
          <div>นามสกุล</div>
          <ValidationProvider rules="required" name="นามสกุล" v-slot="{ errors }">
            <custom-input type="text" dense solo v-model="input.last_name" outlined :error-messages="errors"></custom-input>
          </ValidationProvider>
          <div>เบอร์โทรศัพท์</div>
          <ValidationProvider
            :rules="{
              required: true,
              thai_phonenumber: true,
              digits: 10,
              regex: /^[0][3-9][0-9]{4}[0-9]{4}$/,
            }"
            name="เบอร์โทรศัพท์"
            v-slot="{ errors }"
          >
            <custom-input type="tel" maxlength="10" numberOnly dense solo v-model="input.tel" outlined :error-messages="errors"></custom-input>
          </ValidationProvider>
          <div>สาขาที่ต้องรับ</div>
          <ValidationProvider
            :rules="{
              required: true,
            }"
            name="สาขาที่ต้องรับ"
            v-slot="{ errors }"
          >
            <v-select :error-messages="errors" v-model="input.branch_id" outlined :items="branchData" item-value="id" item-text="name"></v-select>
          </ValidationProvider>
        </template>
        <b>กรุณาแนบภาพบัตรประชาชน</b>
        <div>ภาพบัตรประชาชน</div>
        <ValidationProvider rules="required" name="ใบเสร็จ" v-slot="{ errors }">
          <UploadDocument :errors="errors" :key="keyupload" :showpreview="true" v-model="input.identity_image" :max="1"> </UploadDocument>
        </ValidationProvider>
      </ValidationObserver>
      <v-btn color="main-color" class="white--text mt-16" block @click="onSubmit" rounded large>ยืนยัน</v-btn>
    </div>

    <v-dialog v-model="showSuccessDialog" width="450px" height="450px" persistent>
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <v-icon color="primary" large class="mb-4" style="font-size: 95px">mdi-check-circle</v-icon>
            <div class="mb-4">
              <h3><span class="primary--text">แลกรางวัลสำเร็จ</span><br />กรุณารอการอัปเดตสถานะจัดส่ง<br />จากเจ้าหน้าที่</h3>
            </div>
            <v-btn color="main-color" class="white--text" block @click="$router.push({ name: 'profile' })" rounded large>ตกลง</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm Dialog -->
    <v-dialog v-model="showConfirmDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <div class="mb-4">
              <h3>ยืนยันการแลกของรางวัลหรือไม่</h3>
            </div>
            <v-btn outlined color="gray" class="mr-2 black--text" @click="showConfirmDialog = false" rounded large>ยกเลิก</v-btn>
            <v-btn color="main-color" class="white--text" @click="sendReward" rounded large>ยืนยัน</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
import UploadDocument from "@/components/UploadFile.vue";
export default {
  components: {
    UploadDocument,
  },
  data() {
    return {
      showSuccessDialog: false,
      showConfirmDialog: false,
      input: {
        first_name: "",
        last_name: "",
        tel: "",
        branch_id: null,
        identity_image: [],
      },
      keyupload: 0,
      has_ecoupon: false,
      type: null,
      branchData: [],
      campaign_id: null,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (this.$route.query.type && this.$route.query.type == "updateinfo") {
      this.type = this.$route.query.type;
      this.has_ecoupon = this.$route.query.has_ecoupon && this.$route.query.has_ecoupon == "true" ? true : false;
      //
    } else {
      let cart = this.$store.getters.getCart;
      if (cart && cart.length == 0) {
        this.$router.push({ name: "none" });
      } else {
        for (let i = 0; i < cart.length; i++) {
          const item = cart[i];
          if (item.campaign_id) {
            this.campaign_id = item.campaign_id;
            break;
          }
        }
      }
    }

    let userData = this.$store.state.userData;
    this.input.first_name = userData.first_name;
    this.input.last_name = userData.last_name;

    await this.getLatestRequest();
    await this.getBranch();
  },
  methods: {
    onSelectAddress(address) {
      this.input.district = address.amphoe;
      this.input.province = address.province;
      this.input.subdistrict = address.district;
      this.input.zipcode = address.zipcode;
    },
    onClose() {
      this.showSuccessDialog = false;
      this.$liff.closeWindow();
    },
    async onSubmit() {
      let valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      this.showConfirmDialog = true;
    },
    async sendReward() {
      const vm = this;
      this.$store.commit("setLoading", true);
      this.showConfirmDialog = false;
      const branch = this.branchData.find((branch) => branch.id == vm.input.branch_id);
      let data = {
        user_id: this.userId,
        page_id: this.pageId,
        first_name: this.input.first_name,
        last_name: this.input.last_name,
        phone_number: this.input.tel,
        branch_id: this.input.branch_id,
        identity_image: this.input.identity_image[0],
      };

      if (branch && branch.name) {
        data.branch_name = branch.name;
      }

      let result;

      if (this.$route.query.type && this.$route.query.type == "updateinfo") {
        data.rewardId = this.$route.query.rewardId || null;
        data.has_ecoupon = (this.$route.query.has_ecoupon && this.$route.query.has_ecoupon == "true") || false;
        try {
          result = await api.FilloutLuckydrawRewardInfo(data);
          this.$store.commit("setLoading", false);
          if (result && result.status) {
            this.showSuccessDialog = true;
          }
        } catch (error) {
          let reason = "";
          if (error.response && error.response.data && error.response.data.message) {
            reason = error.response.data.message;
          } else {
            reason = error.toString();
          }
          this.$swal.fire({ text: reason });
        }
      } else {
        try {
          result = await api.SaveRewardRequest(data);
          this.showSuccessDialog = true;
        } catch (error) {
          this.$swal.fire({ text: error.message });
        } finally {
          this.$store.commit("setLoading", false);
        }
      }
    },
    async getBranch() {
      this.$store.commit("setLoading", true);
      let data = {
        page_id: this.pageId,
        campaign_id: this.campaign_id,
        limit: 99999,
        offset: 0,
      };
      try {
        let result = await api.getBranch(data);
        this.branchData = result.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    async getLatestRequest() {
      this.$store.commit("setLoading", true);
      let data = {
        page_id: this.pageId,
        user_id: this.userId,
        limit: 1,
        offset: 0,
      };
      try {
        let result = await api.GetLatestRewardCampaignRequest(data);
        result = result.data;
        this.$store.commit("setLoading", false);
        if (result.data && result.data.length) {
          let lastResult = result.data[0];
          this.input.first_name = lastResult.first_name;
          this.input.last_name = lastResult.last_name;
          this.input.tel = lastResult.phone_number;
          if (lastResult.identity_image) this.input.identity_image.push(lastResult.identity_image);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
