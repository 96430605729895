import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
import api from "@/api";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageId: process.env.VUE_APP_PAGE_ID,
    lineProfile: null,
    userId: null,
    userData: {
      firstName: "",
      lastName: "",
      phone: "",
      picture: "",
    },
    register: {
      phone: "",
      firstName: "",
      lastName: "",
      job: "",
      term: false,
      privacy: false,
    },
    cart: [],
    loading: false,
    query: null,
    IsClickReward: false,
    remainingPoints: 0,
    originalPoints: 0,
    requirePoints: 0,
    cartLoading: false,
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setLineProfile(state, payload) {
      state.lineProfile = payload;
      state.userId = payload.userId + "__" + state.pageId;
    },
    setAddCart(state, payload) {
      state.cart.push(payload);
    },
    editCart(state, payload) {
      Vue.set(state.cart[payload.index], "quantity", payload.quantity);
    },
    deleteCart(state, payload) {
      state.cart.splice(payload.index, 1);
    },
    clearCart(state) {
      state.cart = [];
    },
    calculateRemainingPoint(state) {
      let requirePoints = 0;
      if (state.cart && state.cart.length > 0) {
        requirePoints = state.cart.map((m) => Number(m.points)).reduce((prev, curr) => prev + parseInt(curr));
      }
      state.requirePoints = requirePoints;
      state.remainingPoints = state.originalPoints - requirePoints;
    },
    setCart(state, payload) {
      state.cart = payload;
    },
    setCartLoading(state, payload) {
      state.cartLoading = payload;
    },
  },
  actions: {
    sendMessage({ state }, message) {
      var url = "https://communitybotbyzwizai.herokuapp.com/zwiz-pushmsg-webbrowser";
      var data = {
        pageid: state.pageId,
        userid: state.userId,
        message,
      };
      if (window.liff.isInClient()) {
        try {
          window.liff.sendMessages([
            {
              text: message,
              type: "text",
            },
          ]);
        } catch (error) {
          alert(JSON.stringify(error));
        }
      } else {
        axios.post(url, data).then((response) => {
          if (response.data.responsecode != 200) {
            if (response.data.reason) {
              Swal.fire({
                text: response.data.reason,
              });
            }
          }
        });
      }
    },
    async addToCard_v3({ state, commit, dispatch }, { data, mode }) {
      let reward = data;
      if (mode) {
        commit("setCartLoading", true);
        commit("setLoading", true);
        const index = state.cart.findIndex((f) => f.product_id == reward.product_id);
        if (reward.quantity <= 0) {
          // remove product in cart
          state.cart.splice(index, 1);
          let data = {
            page_id: state.pageId,
            user_id: state.userId,
            carts: [...state.cart],
          };
          await api.AddRewardToCart(data);
          dispatch("getCart");
        } else {
          //update product in cart
          if (mode === "+") {
            reward.quantity--;
          } else if (mode === "-") {
            reward.quantity++;
          }

          if (index >= 0) {
            if (mode === "+") {
              const newPoint = (Number(reward.points) / Number(reward.quantity)) * (Number(reward.quantity) + 1);
              state.cart[index].points = newPoint;
              reward.quantity++;
            } else if (mode === "-") {
              const newPoint = (Number(reward.points) / Number(reward.quantity)) * (Number(reward.quantity) - 1);
              state.cart[index].points = newPoint;
              reward.quantity--;
            }
            state.cart[index].quantity = Number(reward.quantity);
            let data = {
              page_id: state.pageId,
              user_id: state.userId,
              carts: [...state.cart],
            };
            await api.AddRewardToCart(data);
            dispatch("getCart");
          }
        }

        commit("calculateRemainingPoint");
        commit("setCartLoading", false);
        commit("setLoading", false);
      }
    },
    async addToCard_v2({ state, commit, dispatch }, reward) {
      commit("setCartLoading", true);
      commit("setLoading", true);
      reward.productid = reward.id;

      const index = state.cart.findIndex((f) => f.product_id == reward.id || f.productid == reward.id);

      if (index >= 0) {
        const newPoint = Number(state.cart[index].points) + Number(reward.points) * Number(reward.quantity);
        state.cart[index].quantity = Number(state.cart[index].quantity) + Number(reward.quantity);
        state.cart[index].points = newPoint;
        let data = {
          page_id: state.pageId,
          user_id: state.userId,
          carts: [...state.cart],
        };
        await api.AddRewardToCart(data);
        state.cart[index].points = state.cart[index].points / state.cart[index].quantity;

        dispatch("getCart");
      } else {
        // add new
        state.cart.push({
          product_id: reward.id,
          campaign_id: reward.campaign_id,
          product_name: reward.name,
          quantity: reward.quantity,
          points: reward.points * reward.quantity,
        });
        let data = {
          page_id: state.pageId,
          user_id: state.userId,
          carts: [...state.cart],
        };
        await api.AddRewardToCart(data);
        dispatch("getCart");
      }
      commit("calculateRemainingPoint");
      commit("setCartLoading", false);
      commit("setLoading", false);
    },
    async getCart({ state, dispatch }) {
      let data = {
        user_id: state.userId,
        page_id: state.pageId,
      };
      let result = await api.getMyCart(data);
      if (result && result.data && result.data.data) {
        result = result.data;
        let rewards = [];
        result.data.forEach((d) => {
          rewards.push({
            ...d,
            name: d.productname,
            points: d.points,
          });
        });
        dispatch("setCart", rewards);
      }
    },
    setCart({ commit }, reward) {
      commit("setCart", reward);
      commit("calculateRemainingPoint");
    },
  },
  getters: {
    getCart(state) {
      return state.cart;
    },
  },
});
